import { useCreateAccount } from '@formbio/auth';
import {
  AuthErrorMessage,
  FormCheckbox,
  FormInputItem,
  Grid,
  Link,
} from '@formbio/ui';
import {
  getEmailValidator,
  getPasswordValidator,
} from '@formbio/ui/utils/yupHelper';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import AuthButton from '../AuthButton';
import Form from '../Form';

/**
 * types
 */
type FormData = {
  email: string;
  password: string;
  confirmedPassword: string;
  displayName: string;
  terms?: boolean;
};

type InvitationFormProps = {
  email: string;
  hideNetworkErrors?: boolean;
  onSuccess?: () => void;
};

/**
 * schema
 */
const termsContent = 'Please accept Terms and Privacy Policy';
const schema = yup
  .object({
    email: getEmailValidator(
      'Example of a valid email: myemail@domain.com',
    ).required(),
    password: getPasswordValidator().required(),
    confirmedPassword: yup
      .string()
      .required('Please confirm password')
      .oneOf([yup.ref('password'), ''], 'Must match Password'),
    displayName: yup.string().trim().required('Please enter a name'),
    terms: yup.boolean().oneOf([true], termsContent),
  })
  .required();
// TODO for now we are not using hideNetworkErrors
// because we'll want to extract auth components to a separate package
// which will not have access to LaunchDarkly (where hideNetwrorkErrors is coming from)
export default function InvitationForm({
  email,
  hideNetworkErrors,
  onSuccess,
}: InvitationFormProps) {
  const { request, loading, error } = useCreateAccount(
    false,
    hideNetworkErrors,
  );

  const content: { [key: string]: string } = {
    terms_link_alt: 'Form Bio, Inc. Terms',
    policy_link_alt: 'Form Bio, Inc. Privacy Policy',
  };

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email,
      terms: false,
    },
  });

  const onSubmit = async ({ email, password, displayName }: FormData) => {
    await request({
      email,
      password,
      displayName,
      onSuccess,
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormInputItem
        label='Email'
        placeholder='Enter Email'
        type='email'
        registerProps={register('email')}
        errorMessage={errors.email?.message}
        disabled
      />
      <FormInputItem
        label='Password'
        placeholder='Enter Password'
        type='password'
        registerProps={register('password')}
        errorMessage={errors.password?.message}
        helpText={'Minimum 8 characters'}
        required
      />
      <FormInputItem
        label='Confirm Password'
        placeholder='Confirm Password'
        type='password'
        registerProps={register('confirmedPassword')}
        errorMessage={errors.confirmedPassword?.message}
        required
      />
      <FormInputItem
        label='Full Name'
        placeholder='Full Name'
        registerProps={register('displayName')}
        required
        errorMessage={errors.displayName?.message}
      />
      <FormCheckbox
        label={
          <>
            I agree to the Form Bio{' '}
            <Link
              href='https://www.formbio.com/terms-of-use'
              target='_blank'
              aria-label={content.terms_link_alt}
            >
              Terms
            </Link>{' '}
            and{' '}
            <Link
              href='https://www.formbio.com/privacy-policy'
              target='_blank'
              aria-label={content.terms_link_alt}
            >
              Privacy Policy
            </Link>
          </>
        }
        registerProps={register('terms')}
        required
        errorMessage={errors.terms?.message}
        inputProps={{
          'data-testid': 'terms-privacy-policy',
        }}
      />
      {error && <AuthErrorMessage error={error} />}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AuthButton
            type='submit'
            disabled={(isDirty && Object.keys(errors).length > 0) || loading}
            useGoogleBtnWidth
            id='invitation-form-submit'
          >
            Sign Up
          </AuthButton>
        </Grid>
      </Grid>
    </Form>
  );
}
