import { AuthUser, MFACodeDialog, useLogin } from '@formbio/auth';
import {
  AuthErrorMessage,
  FormInputItem,
  NextMuiLink,
  Typography,
  styled,
} from '@formbio/ui';
import {
  getEmailValidator,
  getPasswordValidator,
} from '@formbio/ui/utils/yupHelper';
import { yupResolver } from '@hookform/resolvers/yup';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UrlObject } from 'url';
import * as yup from 'yup';
import AuthButton from '../AuthButton';
import Form from '../Form';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

type FormData = {
  email: string;
  password: string;
};

const schema = yup
  .object({
    email: getEmailValidator(
      'Example of a valid email: myemail@domain.com',
    ).required('Please enter an email address'),
    password: getPasswordValidator().required(),
  })
  .required();

type Props = {
  onSuccess?: (user: AuthUser) => Promise<void>;
  forgotPasswordNextHref?: UrlObject;
  disableAccountCreation?: boolean;
  captureError: ({
    name,
    error,
    context,
  }: {
    name: string;
    error: unknown;
    context: { [key: string]: unknown } | null | undefined;
  }) => void;
};

export default function LoginForm({
  onSuccess,
  forgotPasswordNextHref,
  disableAccountCreation,
  captureError,
}: Props) {
  const { request, loading, error } = useLogin();
  const [isMFAVisible, setIsMFAVisible] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({ resolver: yupResolver(schema) });

  const onSubmit = async ({ email, password }: FormData) => {
    const user = await request(email, password);
    if (user) {
      onSuccess?.(user);
    }
  };

  const isMFAError =
    error &&
    error instanceof FirebaseError &&
    error.code === AuthErrorCodes.MFA_REQUIRED;

  useEffect(() => {
    if (isMFAError) {
      setIsMFAVisible(true);
    }
  }, [error]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormInputItem
          label='Email'
          placeholder='Enter Email'
          type='email'
          registerProps={register('email')}
          errorMessage={errors.email?.message}
          required
          disabled={isMFAVisible}
        />
        <FormInputItem
          label='Password'
          placeholder='Enter Password'
          type='password'
          registerProps={register('password')}
          errorMessage={errors.password?.message}
          required
          disabled={isMFAVisible}
        />

        {!isMFAError && error && <AuthErrorMessage error={error} />}

        {forgotPasswordNextHref && (
          <StyledTypography>
            {/* <NextMuiLink nextHref={navRoutes.forgotPassword.pathname}> */}
            <NextMuiLink nextHref={forgotPasswordNextHref}>
              Forgot Password?
            </NextMuiLink>
          </StyledTypography>
        )}

        <AuthButton
          type='submit'
          disabled={loading || isMFAVisible}
          useGoogleBtnWidth={!disableAccountCreation}
        >
          Sign In
        </AuthButton>
      </Form>
      <MFACodeDialog
        open={isMFAVisible}
        preventOnClose
        onClose={() => setIsMFAVisible(false)}
        onSuccess={user => {
          setIsMFAVisible(false);
          onSuccess?.(user);
        }}
        raisedError={error}
        logoutButton
        captureError={captureError}
      />
    </>
  );
}
