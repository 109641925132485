import { useState } from 'react';
import { updatePassword } from 'firebase/auth';
import { FirebaseError } from '@firebase/util';
import { useAuth } from './useAuth';
import { getErrorMessage } from '../utils';

function useChangePassword(
  captureError: ({ name, error }: { name: string; error: unknown }) => void,
) {
  const { user, loading: isUserLoading } = useAuth();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const request = async ({ password }: { password: string }) => {
    setLoading(false);
    setError('');
    try {
      if (!isUserLoading && user) {
        await updatePassword(user, password);
      }
    } catch (error: unknown) {
      captureError({
        name: 'useChangePassword hook error',
        error: error,
      });
      const message =
        error instanceof FirebaseError
          ? getErrorMessage(error)
          : 'Incorrect credentials.';
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return { request, error, loading };
}

export { useChangePassword };
