import { ConflictStrategy, Label } from '@formbio/api';
export interface Vault {
  contents: VaultContents;
}
export interface VaultObject {
  createdAt?: string;
  updatedAt?: string;
  metadata: { [key: string]: string } | null;
  size: number;
  contentType: string;
  bucket: string;
  name: string;
  crc32c: number;
  labels?: Label[];
}

// eg. of vaultpage: {"cursor":1,"start":1,"end":19}
interface VaultPage {
  cursor: number;
  start: number;
  end: number;
}
export interface PaginatedVault {
  count: number;
  totalPages: number;
  pageInfo: VaultPage;
  pageItems: VaultObject[];
}

type VaultNodeBase = {
  id: string;
  bucket: string;
  name: string;
  fullPath: string;
  updatedAt?: string;
  createdAt?: string;
  // User Id:
  createdBy?: string;
  type: VaultNodeType;
  labels?: Label[];
};

export enum VaultNodeType {
  File = 'File',
  Directory = 'Folder',
}

export type VaultNode = VaultFile | VaultDirectory;

export interface VaultFile extends VaultNodeBase {
  latestRunId?: string;
  filetype: string;
  mimetype?: string;
  type: VaultNodeType.File;
  size: number;
  crc32c: number;
  orgId?: string;
  pid?: string;
}

export interface VaultDirectory extends VaultNodeBase {
  contents: VaultContents;
  type: VaultNodeType.Directory;
}

export type VaultContents = { [key: string]: VaultFile | VaultDirectory };

export function isFile(node: VaultNode): node is VaultFile {
  return node.type === VaultNodeType.File;
}

export function isDirectory(node: VaultNode): node is VaultDirectory {
  return node.type === VaultNodeType.Directory;
}

export interface VaultRequestParams {
  orgId: string;
  pid: string;
  apiUrl: string;
}
export interface AddDirectoryToVault extends VaultRequestParams {
  newDirPath: string;
  conflictStrategy: ConflictStrategy;
}
