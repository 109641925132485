import { NextMuiLink, NextMuiLinkProps } from '@formbio/ui';
import { PropsWithChildren } from 'react';

export default function ConditionalLink({
  isLink,
  children,
  underline = 'none',
  ...linkProps
}: PropsWithChildren<NextMuiLinkProps & { isLink?: boolean }>) {
  if (isLink) {
    return (
      // blame adam - MUI styled doesn't like the way NextMuiLink types its props so doing these as inline
      <NextMuiLink underline={underline} color='inherit' {...linkProps}>
        {children}
      </NextMuiLink>
    );
  }
  return <>{children}</>;
}
