import { useEffect } from 'react';
import { useAuth, useReAuth } from '../../hooks';
import { AuthErrorCodes } from 'firebase/auth';
import { getErrorMessage } from '../../utils';

import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Spacer,
  styled,
  FormPasswordItem,
} from '@formbio/ui';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPasswordValidator } from '@formbio/ui/utils/yupHelper';
import { FirebaseApp } from '@firebase/app';

const StyledDialogContent = styled(DialogContent)({
  maxWidth: 500,
});

const schema = yup
  .object({
    password: getPasswordValidator().required(),
  })
  .required();

type FormData = {
  password: string;
};

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  onLoading?: (status: boolean) => void;
  app: FirebaseApp;
  captureError: ({
    name,
    error,
    context,
  }: {
    name: string;
    error: unknown;
    context:
      | {
          [key: string]: unknown;
        }
      | null
      | undefined;
  }) => void;
};

const ReAuthDialog = ({
  onClose,
  onSuccess,
  onLoading,
  app,
  captureError,
}: Props) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { user, verifyMFA, isMfaSuccess } = useAuth();
  const {
    request: reAuth,
    error: reAuthError,
    loading: isReAuthLoading,
  } = useReAuth(app, captureError);

  useEffect(() => {
    if (reAuthError) {
      if (reAuthError.code === AuthErrorCodes.MFA_REQUIRED) {
        // show MFA dialog (even after password)
        verifyMFA(reAuthError);
      } else {
        setError('password', {
          type: 'manual',
          message: getErrorMessage(reAuthError),
        });
      }
    }
  }, [reAuthError]);

  useEffect(() => {
    if (isMfaSuccess) {
      onSuccess();
    }
  }, [isMfaSuccess]);

  useEffect(() => {
    if (onLoading) {
      onLoading(isReAuthLoading);
    }
  }, [isReAuthLoading]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async ({ password }: FormData) => {
    const reAuthUser = await reAuth({ currentPassword: password });
    if (!isReAuthLoading && reAuthUser && !reAuthError) {
      onSuccess();
    }
  };

  return (
    <Dialog open onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogHeader title='Re-authentication' onClose={handleClose} />
        <StyledDialogContent>
          <Typography>
            This security sensitive operation requires re-authentication.
          </Typography>
          <Spacer size='xl' />
          <input
            aria-hidden='true'
            type='hidden'
            defaultValue={user?.email || ''}
            autoComplete='username'
            autoCapitalize='off'
            name='username'
            disabled
          />
          <FormPasswordItem
            label='Password'
            placeholder='Enter Password'
            registerProps={register('password')}
            errorMessage={errors.password?.message}
            required
            disabled={isReAuthLoading}
            inputProps={{
              autoComplete: 'current-password',
            }}
          />
        </StyledDialogContent>
        <DialogActions>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={isSubmitting || isReAuthLoading}
          >
            Verify Password
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReAuthDialog;
