import {
  AuthError,
  User,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import {
  isAxiosError,
  useAddOrganization,
  useAddProject,
  useVerificationEmail,
} from '@formbio/api';
import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useOnNetworkError } from '@formbio/notifications';
import { v4 as uuidv4 } from 'uuid';

export function useCreateAccount(
  requireVerification = true,
  hideNetworkErrors?: boolean,
) {
  const { auth } = useAuth();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState(false);
  const onNetWorkError = useOnNetworkError(hideNetworkErrors);
  const verificationMutation = useVerificationEmail({
    onError: onNetWorkError,
  });

  const request = async ({
    email,
    password,
    displayName,
    onSuccess,
  }: {
    email: string;
    password: string;
    displayName: string;
    onSuccess?: () => void;
  }) => {
    setLoading(true);
    setError(undefined);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      if (userCredential.user) {
        requireVerification && verificationMutation.mutate({ email });
        await updateProfile(userCredential.user, { displayName });
      }

      onSuccess && onSuccess();

      return userCredential.user;
    } catch (error) {
      setError(error as AuthError);
    } finally {
      setLoading(false);
    }
  };

  return {
    request,
    error,
    loading,
  };
}

export function useCreateIsaAccount(
  onSuccess: ({ orgId, pid }: { orgId: string; pid: string }) => void,
  hideNetworkErrors?: boolean,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | Error>();
  const { mutate: createProject } = useAddProject({
    onSuccess: (data, vars) =>
      onSuccess({
        orgId: vars.oid,
        pid: data.data.id,
      }),
    onError: error => {
      setIsLoading(false);
      setError(
        isAxiosError(error) && error.response?.data.message
          ? error.response.data.message
          : 'An error occurred',
      );
    },
  });
  const { mutate: createOrgMutation } = useAddOrganization({
    onSuccess: res => {
      // Create project
      createProject({
        name: 'In Silico Assay',
        // @ts-expect-error res type is unknown, but this is fine for now
        oid: res?.data?.id,
        ignoreVerification: true,
        isIsa: true,
      });
    },
    onError: error => {
      setIsLoading(false);
      setError(
        isAxiosError(error) && error.response?.data.message
          ? error.response.data.message
          : 'An error occurred',
      );
    },
  });
  const {
    error: accountError,
    request: createAccountReq,
    // loading: createAccountLoading,
    // error: createAccountError,
  } = useCreateAccount(false, hideNetworkErrors);

  useEffect(() => {
    if (accountError) {
      setIsLoading(false);
    }
  }, [accountError]);

  async function handleSignUpSubmit({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    setIsLoading(true);
    try {
      await createAccountReq({
        email,
        password,
        displayName: email,
        onSuccess: () => {
          // Create org
          createOrgMutation({
            ownerEmail: email,
            name: `synth-assay-${uuidv4()}`,
            ignoreVerification: true,
            isIsa: true,
          });
        },
      });
    } catch (error) {
      console.error(error);
      setError(
        isAxiosError(error) && error.response?.data.message
          ? error.response.data.message
          : 'An error occurred',
      );
      setIsLoading(false);
    }
  }

  async function handleSignUpWithAccount(user: User) {
    if (!user.email) {
      setIsLoading(false);
      setError(new Error('Email is required'));
      return;
    }
    setIsLoading(true);
    createOrgMutation({
      ownerEmail: user.email,
      name: `synth-assay-${uuidv4()}`,
      ignoreVerification: true,
      isIsa: true,
    });
  }

  return {
    handleSignUpSubmit,
    isLoading,
    error: error || accountError,
    handleSignUpWithAccount,
  };
}
