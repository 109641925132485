import { NextMuiLink, Typography, styled } from '@formbio/ui';
import { ArrowLeft as ArrowLeftIcon } from '@formbio/ui/components/Icons';
import { UrlObject } from 'url';

const StyledButtonWrapper = styled('span')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  '.secondary': {
    color: theme.palette.secondary.main,
  },
}));

const BackToSignIn = ({ nextHref }: { nextHref: UrlObject }) => {
  return (
    <NextMuiLink nextHref={nextHref} aria-label={`Back to the Sign In Page`}>
      <StyledButtonWrapper>
        <ArrowLeftIcon className='secondary' />
        <Typography component='span' color='secondary'>
          Go back to Sign In
        </Typography>
      </StyledButtonWrapper>
    </NextMuiLink>
  );
};

export default BackToSignIn;
