import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

export function useInitLaunchDarkly() {
  const ldClient = useLDClient();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  useEffect(() => {
    if (ldClient && !isInitializing) {
      setIsInitializing(true);
      ldClient
        .waitForInitialization()
        .then(() => {
          setIsReady(true);
        })
        .catch(_err => {
          setIsFailed(true);
        });
    }
  }, [ldClient, isInitializing]);

  return { ready: isReady, failed: isFailed };
}
