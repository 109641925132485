import { Fragment, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import useUrlParams, { QueryParams } from '@formbio/utils';
import { CaretRight as CaretRightIcon } from '../../Icons';
import { AutocompleOrgProject } from '../AutocompleteOrgProject';
import OrgProjectMenuItem from '../OrgProjectMenuItem';
import BootstrapTooltip from '../../Tooltip/BootstrapTooltip';
import { ListItemIcon, ListItemText } from '../../List';
import { Divider } from '../../Layout';
import { CircularLoading } from '../../CircularLoading';
import { MenuList } from '../../Menu';
import TextAvatar from '../../Avatar/TextAvatar';
import NextMuiLink from '../../Link/NextMuiLink';
import useOrgProjectSelect from '@formbio/ui/hooks/useOrgProjectSelect';
import { Popover, styled } from '@mui/material';
import { ProjectOrgSelectWithLabel } from '../ProjectOrgSelectWithLabel';
import { ProjectOrgSelectMenuButton } from '../ProjectOrgSelectMenuButton';

const OrgProjectMenu = styled(Popover)(({ theme }) => ({
  '.MuiPaper-root': {
    backgroundColor: theme.palette.primary[900],
    padding: 0,
    margin: 0,
    border: `1px solid ${theme.palette.primary[700]}`,
  },
  '.MuiMenuItem-root': {
    borderRadius: '4px',
  },
  '.MuiList-root': {
    padding: theme.spacing(0.5, 0, 0, 0),
  },
  '.MuiMenuItem-root.Mui-selected': {
    backgroundColor: theme.palette.primary[600],
  },
  '.MuiTypography-root': {
    color: theme.palette.navItemSelectedTextColor,
  },
  '.flex': {
    display: 'flex',
    gap: theme.spacing(1),
  },
  '.padding-left': {
    paddingLeft: theme.spacing(2.5),
  },
  '.divider': {
    backgroundColor: theme.palette.primary[700],
  },
  '.panel-menu': {
    padding: 0,
    margin: 0,
    maxHeight: 400,
    width: 275,
    overflowY: 'auto',
    overflowX: 'hidden',
    '.two-panels': {
      width: '275px',
    },
  },
  '.slanted': {
    transform: 'rotate(45deg)',
  },
  '.current': {
    padding: theme.spacing(1, 1, 0),
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
    '.MuiListItemText-root': {
      flex: '0 0 auto',
      maxWidth: '200px',
    },
  },
  '.center': {
    alignItems: 'center',
  },
  '.end-button': {
    backgroundColor: theme.palette.primary[800],
    margin: theme.spacing(1, 1.5, 1.5, 1.5),
    padding: theme.spacing(1.5),
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.primary[700],
    },
    '.caret-icon': {
      color: theme.palette.primary.contrastText,
      width: theme.spacing(2),
    },
  },
  '.list-wrapper': {
    padding: theme.spacing(0, 1),
    // width: '100%',
  },
  '.top-item': {
    marginLeft: theme.spacing(1),
    '.MuiTypography-body1': {
      fontSize: theme.typography.body2?.fontSize,
      color: theme.palette.primary[600],
    },
    '.MuiTypography-body2': {
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 600,
      color: theme.palette.primary[50],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  '.list-item': {
    marginLeft: theme.spacing(1),
    '.MuiTypography-body1': {
      fontSize: theme.typography.body1?.fontSize,
      color: theme.palette.primary[50],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

const MenuDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderColor: theme.palette.primary[700],
}));

const ListItemWithOptionalToolip = ({
  children,
  name,
}: {
  children: JSX.Element;
  name: string;
}) =>
  name.length > 20 ? (
    <BootstrapTooltip title={name}>{children}</BootstrapTooltip>
  ) : (
    children
  );

export function ProjectSelect({
  mini,
  projectPathname,
  orgPathname,
  withLabel,
}: {
  mini?: boolean;
  projectPathname: string;
  orgPathname?: string;
  withLabel?: boolean;
}) {
  const router = useRouter();
  const { pid } = useUrlParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const autoFocusRef = useRef<HTMLLIElement | null>(null);
  const open = Boolean(anchorEl);
  const { sortedProjects, currentOrgProject, panels, isLoading } =
    useOrgProjectSelect();

  useEffect(() => {
    if (anchorEl && autoFocusRef.current) {
      autoFocusRef.current.focus();
    }
  }, [anchorEl]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  function getTitle() {
    if (mini) {
      return (currentOrgProject.project?.name || 'Project')[0];
    }
    return currentOrgProject.project?.name || 'Project';
  }

  if (!pid) {
    return null;
  }

  return (
    <>
      {withLabel ? (
        <ProjectOrgSelectWithLabel
          label='Project'
          mini={mini}
          tooltip={currentOrgProject.project?.name || 'Project'}
          title={getTitle()}
          handleMenuOpen={handleOpen}
          open={open}
        />
      ) : (
        <ProjectOrgSelectMenuButton
          title={getTitle()}
          mini={mini}
          handleMenuOpen={handleOpen}
          open={open}
        />
      )}
      <OrgProjectMenu
        open={open}
        id='project-org-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div className='current'>
          <ListItemText
            className='top-item'
            primary='Organization'
            secondary={currentOrgProject.org?.name}
          />
          <Divider
            orientation='vertical'
            flexItem
            className='divider slanted'
          />
          <ListItemText
            className='top-item'
            primary='Project'
            secondary={currentOrgProject.project?.name}
          />
        </div>
        <MenuDivider />
        {isLoading ? (
          <CircularLoading />
        ) : (
          <AutocompleOrgProject
            options={sortedProjects}
            projectId={pid}
            onChange={project => {
              handleClose();
              router.push({
                pathname: projectPathname,
                query: {
                  [QueryParams.orgId]: project.organization?.id,
                  [QueryParams.pid]: project.id,
                },
              });
            }}
          />
        )}
        <MenuDivider />
        <div className='flex'>
          {panels.map((panel, index) => (
            <Fragment key={panel.key}>
              <div className='list-wrapper'>
                <ListItemText primary={`Select ${panel.key}:`} />
                <MenuList
                  className={`panel-menu dark-scroll ${
                    panels.length > 1 ? 'two-panels' : ''
                  }`}
                >
                  {panel.items.map(item => (
                    <OrgProjectMenuItem
                      key={item.orgId + item.id}
                      selected={item.selected}
                      disabled={item.disabled}
                      onClick={item.pid ? handleClose : item.onClick}
                      nextHref={
                        item.pid
                          ? {
                              pathname: projectPathname,
                              query: {
                                [QueryParams.orgId]: item.orgId,
                                [QueryParams.pid]: item.id,
                              },
                            }
                          : undefined
                      }
                    >
                      <ListItemIcon>
                        <TextAvatar text={item.name} variant='square' />
                      </ListItemIcon>

                      <ListItemWithOptionalToolip name={item.name}>
                        <ListItemText
                          primary={item.name}
                          className='list-item'
                        />
                      </ListItemWithOptionalToolip>
                    </OrgProjectMenuItem>
                  ))}
                </MenuList>
              </div>
              {index === 0 && panels.length > 1 && (
                <Divider orientation='vertical' flexItem className='divider' />
              )}
            </Fragment>
          ))}
        </div>
        {orgPathname && (
          <NextMuiLink
            nextHref={{
              pathname: orgPathname,
              query: {
                [QueryParams.orgId]: currentOrgProject.org?.id,
              },
            }}
            underline='none'
          >
            <div className='end-button flex center'>
              <span>All Projects</span>
              <CaretRightIcon className='caret-icon' />
            </div>
          </NextMuiLink>
        )}
      </OrgProjectMenu>
    </>
  );
}
