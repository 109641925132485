import { FeatureNames, useAccountQuotas } from '@formbio/api';
import useUrlParams from '@formbio/utils';

/**
 * This is the boolean useQuotas hook.
 * It fetches the quotas and returns a function that can be used to check if a quota is passed.
 * Other hooks that need additional quota logic (like useVMQuotas) should use this hook.
 * It should be used for boolean quota checks only, where the 1 is meant to represent a pass and 0 a fail.
 */
export default function useAllowBlockQuotas() {
  const { orgId } = useUrlParams();
  const { data: quotas, isPending } = useAccountQuotas(orgId);

  function passesQuota(feature: FeatureNames) {
    const foundFeature = quotas?.features.find((feat) => feat.name === feature);
    return !foundFeature || foundFeature.quota !== 0;
  }

  return { passesQuota, quotas, isPending };
}
