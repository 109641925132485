import { useAllOrganizations, useProjects } from '@formbio/api';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useState } from 'react';
import { Organization } from '@formbio/api';
import useUrlParams from '@formbio/utils';

export type MenuElement = {
  id: string;
  name: string;
  pid?: string;
  orgId: string;
  selected: boolean;
  iconBgColor?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export default function useOrgProjectSelect() {
  const { orgId, pid } = useUrlParams();
  const { data: sortedProjects } = useProjects({
    select: data =>
      sortBy(
        data,
        item =>
          `${item.organization.name.toLowerCase()}:${item.name.toLowerCase()}`,
      ),
  });
  const [activeOrganization, setActiveOrganization] = useState<Organization>();
  const { allOrganizations, isLoading: isLoadingOrganizations } =
    useAllOrganizations();

  useEffect(() => {
    setActiveOrganization(allOrganizations?.find(org => org.id === orgId));
  }, [allOrganizations]);

  const orgMenuElements: MenuElement[] = useMemo(
    () =>
      !isLoadingOrganizations && allOrganizations
        ? allOrganizations.map(item => ({
            id: item.id,
            name: item.name,
            orgId: item.id,
            selected: item.id === activeOrganization?.id,
            onClick: () => {
              setActiveOrganization(item);
            },
            iconBgColor: 'main',
            disabled:
              sortedProjects?.filter(
                project => project.organization.id === item.id,
              ).length === 0,
          }))
        : [],
    [allOrganizations, orgId, activeOrganization?.id],
  );

  const projectMenuElements: MenuElement[] = useMemo(
    () =>
      sortedProjects && activeOrganization?.id
        ? sortedProjects
            .filter(
              project => project.organization.id === activeOrganization.id,
            )
            .map(item => ({
              id: item.id,
              name: item.name,
              pid: item.id,
              orgId: item.organization.id,
              selected:
                pid === item.id &&
                activeOrganization?.id === item.organization.id,
            }))
        : [],
    [sortedProjects, activeOrganization?.id, orgId, pid],
  );

  const panels = useMemo(
    () =>
      orgMenuElements.length > 1
        ? [
            {
              items: orgMenuElements,
              activeElement: allOrganizations.find(org => org.id === orgId),
              key: 'Organization',
            },
            {
              items: projectMenuElements,
              activeElement: sortedProjects?.find(proj => proj.id === pid),
              key: 'Project',
            },
          ]
        : [
            {
              items: projectMenuElements,
              activeElement: sortedProjects?.find(proj => proj.id === pid),
              key: 'Project',
            },
          ],
    [orgMenuElements, projectMenuElements, orgId, pid, sortedProjects],
  );

  const currentOrgProject = useMemo(
    () => ({
      org: allOrganizations.find(org => org.id === orgId),
      project: sortedProjects?.find(proj => proj.id === pid),
    }),
    [allOrganizations, sortedProjects, orgId, pid],
  );

  return {
    sortedProjects: sortedProjects || [],
    currentOrgProject,
    panels,
    isLoading: isLoadingOrganizations || !activeOrganization,
    activeOrganizationId: activeOrganization?.id,
  };
}
