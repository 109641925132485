import { Avatar, styled } from '@mui/material';

export const ColorBackgroundAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'background' && prop !== 'color',
})<{
  background: string;
  color: string;
}>(({ theme, background, color }) => ({
  background,
  color,
  borderRadius: theme.spacing(1),
  width: theme.spacing(6),
  height: theme.spacing(6),
  marginBottom: theme.spacing(2),
}));
