import { Box, styled, Button, ButtonProps } from '@formbio/ui';
import { GOOGLE_SIGN_IN_WIDTH } from '@formbio/ui/components/Button/SignInWithGoogle';

const StyledButtonContainer = styled(Box)<{
  width?: number | string | undefined;
}>(({ theme, width }) => ({
  marginTop: theme.spacing(3),
  paddingRight: theme.spacing(4),
  width: width,
}));

const StyledButton = styled(Button)<{ width?: number | string | undefined }>(
  ({ width }) => ({
    width: width,
  }),
);

type AuthButtonProps = ButtonProps & {
  useGoogleBtnWidth?: boolean | undefined;
};

export default function AuthButton(props: AuthButtonProps) {
  const { useGoogleBtnWidth, ...btnProps } = props;
  return (
    <StyledButtonContainer>
      <StyledButton
        width={useGoogleBtnWidth ? GOOGLE_SIGN_IN_WIDTH : undefined}
        variant='contained'
        color='secondary'
        {...btnProps}
      />
    </StyledButtonContainer>
  );
}
