import { AxiosRequestConfig, AxiosResponse } from '@formbio/api';
import { useCallback, useState } from 'react';

export default function useAbortController(
  fetcher: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse>,
) {
  const [controller, setController] = useState<AbortController>();

  const fetch = useCallback(
    (url: string, config?: AxiosRequestConfig) => {
      const newController = new AbortController();
      setController(newController);
      return fetcher(url, {
        signal: newController.signal,
        ...config,
      });
    },
    [controller, fetcher],
  );

  return {
    fetch,
    abort: controller?.abort.bind(controller),
  };
}
