import { useState } from 'react';
import {
  getAuth,
  TotpMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorError,
  AuthError,
} from 'firebase/auth';

const useMFAResolver = (
  captureError: ({
    name,
    error,
    context,
  }: {
    name: string;
    error: unknown;
    context: { [key: string]: unknown } | null | undefined;
  }) => void,
) => {
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);

  const request = async ({
    code,
    mfaError,
  }: {
    code: string;
    mfaError: MultiFactorError;
  }) => {
    const mfaResolver = getMultiFactorResolver(getAuth(), mfaError);

    setLoading(true);
    setError(undefined);
    try {
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
        mfaResolver.hints[0].uid,
        code,
      );

      const userCredential =
        await mfaResolver.resolveSignIn(multiFactorAssertion);

      return userCredential.user;
    } catch (error: unknown) {
      setError(error as AuthError);
      captureError({
        name: 'useMFAResolver hook error',
        error: error,
        context: undefined,
      });
    } finally {
      setLoading(false);
    }
  };

  return { request, error, loading };
};

export { useMFAResolver };
