import { Project } from '@formbio/api';
import { Popper, styled } from '@mui/material';
import { HTMLAttributes } from 'react';
import { Autocomplete } from '../../Autocomplete';
import { MenuItem } from '../../Menu';
import { TextField } from '../../TextField';

const StyledPopper = styled(Popper)(({ theme }) => ({
  '.MuiAutocomplete-paper': {
    border: `1px solid ${theme.palette.primary[700]}`,
    backgroundColor: theme.palette.primary[800],
    margin: theme.spacing(0.5, -2, 0, 2),
  },
  '.Mui-focused': {
    backgroundColor: theme.palette.primary[700],
  },
  '.autocomplete-menu-item': {
    padding: theme.spacing(1.5),
    margin: theme.spacing(1, 1, 1, 0.5),
    color: theme.palette.primary.contrastText,
    textWrap: 'wrap',
    '&:hover': {
      backgroundColor: theme.palette.primary[700],
    },
    '& .Mui-selected': {
      backgroundColor: 'red',
      ':hover': {
        backgroundColor: theme.palette.primary[600],
      },
    },
  },
  '.MuiAutocomplete-noOptions': {
    color: theme.palette.primary.contrastText,
  },
}));

const StyledDiv = styled('div')(({ theme }) => ({
  '.autocomplete': {
    margin: theme.spacing(1),
    input: {
      color: theme.palette.primary.contrastText,
    },
    button: {
      color: theme.palette.primary.contrastText,
    },
  },
}));

type Props = {
  options: Project[];
  projectId: string;
  onChange: (project: Project) => void;
};
export function AutocompleOrgProject({ projectId, options, onChange }: Props) {
  return (
    <StyledDiv tabIndex={-1}>
      <Autocomplete
        className='autocomplete'
        options={options}
        autoHighlight
        value={options.find(proj => proj.id === projectId) || null}
        onChange={(_event, newProject) => newProject && onChange(newProject)}
        getOptionLabel={option => `${option.organization.name}: ${option.name}`}
        getOptionKey={option => `${option.organization.id}: ${option.id}`}
        renderOption={(props, option) => {
          const { key, ...otherProps } =
            props as HTMLAttributes<HTMLLIElement> & { key: string };
          return (
            <MenuItem
              key={key}
              {...otherProps}
              className='autocomplete-menu-item'
            >
              {option.organization.name}: {option.name}
            </MenuItem>
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus
            onFocus={event => {
              // a bit hacky but without this the textfield doesn't select the text on focus the first time
              setTimeout(() => event.target.select(), 250);
            }}
            placeholder={'Search an org or project'}
          />
        )}
        noOptionsText='No orgs/projects found'
        PopperComponent={StyledPopper}
      />
    </StyledDiv>
  );
}
