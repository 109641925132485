import { useCreateAccount } from '@formbio/auth';
import { AuthErrorMessage, FormInputItem } from '@formbio/ui';
import {
  getEmailValidator,
  getPasswordValidator,
} from '@formbio/ui/utils/yupHelper';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import AuthButton from '../AuthButton';
import Form from '../Form';

type FormData = {
  displayName: string;
  email: string;
  password: string;
  confirmedPassword: string;
};

const schema = yup
  .object({
    displayName: yup
      .string()
      .trim()
      .min(2, 'Name must be at least 2 characters long')
      // alphanumeric, comma, dash, spaces, and accented (e.g Pédro Garcia-A, III)
      .matches(
        /^[a-zA-Z0-9,\-\sÀ-ÿ]+$/,
        'Name must contain alphanumeric characters, commas, dashes, spaces, and accented characters only',
      )
      .required('Please enter a name'),
    email: getEmailValidator(
      'Example of a valid email: myemail@domain.com',
    ).required('Please enter an email address'),
    password: getPasswordValidator().required(),
    confirmedPassword: yup
      .string()
      .required('Please confirm password')
      .oneOf([yup.ref('password'), ''], 'Must match Password'),
  })
  .required();

// TODO for now we are not using hideNetworkErrors
// because we'll want to extract auth components to a separate package
// which will not have access to LaunchDarkly (where hideNetworkErrors is coming from)
export default function CreateAccountForm({
  hideNetworkErrors,
  onSuccess,
}: {
  hideNetworkErrors?: boolean;
  onSuccess?: () => void;
}) {
  const { request, loading, error } = useCreateAccount(
    false,
    hideNetworkErrors,
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email, password, displayName }: FormData) => {
    await request({
      email,
      password,
      displayName,
      onSuccess,
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormInputItem
        label='Full Name'
        placeholder='Full Name'
        registerProps={register('displayName')}
        required
        errorMessage={errors.displayName?.message}
      />
      <FormInputItem
        label='Email'
        placeholder='Enter Email'
        type='email'
        registerProps={register('email')}
        errorMessage={errors.email?.message}
        required
      />
      <FormInputItem
        label='Password'
        placeholder='Enter Password'
        type='password'
        registerProps={register('password')}
        errorMessage={errors.password?.message}
        required
      />
      <FormInputItem
        label='Confirm Password'
        placeholder='Confirm Password'
        type='password'
        registerProps={register('confirmedPassword')}
        errorMessage={errors.confirmedPassword?.message}
        required
      />
      {error && <AuthErrorMessage error={error} />}

      <AuthButton type='submit' disabled={loading}>
        Get Started Now
      </AuthButton>
    </Form>
  );
}
