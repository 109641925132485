import { useEffect, useState } from 'react';

type MainContent = {
  title: string;
  line1: string;
  line2?: string;
};

/**
 * Centralized hook to manage the content of the verify-email page
 * based on the error code.
 */
export default function useVerifyEmailMessage({
  errorCode,
  userEmail,
}: {
  errorCode?: string;
  userEmail?: string | null;
}) {
  const [{ title, line1, line2 }, setTitleDescriptions] = useState<MainContent>(
    {
      title: 'Verify your email address',
      line1: `Your email address has not been verified yet.`,
      line2: `Click the Resend email button below to
        receive another verification email.`,
    },
  );

  function updateContentOnSuccess() {
    setTitleDescriptions({
      title: `Look for the verification link in your inbox`,
      line1: `We sent a verification email to ${userEmail}.`,
    });
  }

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case 'auth/invalid-action-code':
          setTitleDescriptions({
            title: `Look for the verification link in your inbox`,
            line1: `We sent a verification email to ${userEmail}.`,
          });
          break;
        case 'auth/user-not-found':
        case 'auth/expired-action-code':
          setTitleDescriptions({
            title: 'Your verification link has expired',
            line1: `Sorry, your link has expired.`,
            line2: `Please verify your email again.`,
          });
          break;
        case 'auth/user-disabled':
          setTitleDescriptions({
            title: 'User is disabled',
            line1: `Contact an organization admin to enable your account and resend
              the verification link.`,
          });
          break;
        default:
          setTitleDescriptions({
            title: 'An error has occurred',
            line1: `Sorry, an error has occurred and your verification link is
              invalid.`,
            line2: `Please verify your email again.`,
          });
      }
    }
  }, [errorCode]);
  return { title, line1, line2, updateContentOnSuccess };
}
