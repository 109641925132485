import { Agent } from '../../types';
import { api } from '../../utils/api';

export const AUTOMATIC_AGENT: Agent = {
  agentType: 'automatic',
  name: 'Automatic',
};

export async function fetchAll(params: {
  orgId: string;
  pid: string;
}): Promise<Agent[]> {
  return api
    .get<Agent[]>(`/orgs/${params.orgId}/${params.pid}/query/agents`, {
      params,
    })
    .then(({ data }) =>
      data ? [AUTOMATIC_AGENT, ...data] : [AUTOMATIC_AGENT],
    );
}
