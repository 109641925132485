import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ReactNode, InputHTMLAttributes } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import ErrorMessage from '../../Error/ErrorMessage';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined;
};

export default function FormCheckbox({
  label,
  errorMessage,
  registerProps,
  required = false,
  disabled = false,
  description,
  inputProps = {},
}: {
  label: ReactNode;
  errorMessage?: string;
  registerProps: UseFormRegisterReturn;
  required?: boolean;
  disabled?: boolean;
  description?: ReactNode;
  testId?: string;
  inputProps: InputProps;
}) {
  return (
    <>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              required={required}
              disabled={disabled}
              disableRipple
              inputProps={inputProps}
              {...registerProps}
            />
          }
          label={label}
        />
      </FormGroup>
      {description}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
}
