import { useFetchFileContent, useFetchSignedURL } from '@formbio/api';
import { keepPreviousData } from '@tanstack/react-query';

/**
 * A hook to read the content of a file from it's full path
 */
export default function useFileContentFromPath({
  filePath,
  orgId,
  pid,
  select,
  enabled,
}: {
  filePath?: string;
  orgId: string;
  pid: string;
  select?: (data: string) => string;
  enabled?: boolean;
}) {
  const {
    data: signedUrlData,
    isPending: isSignedUrlPending,
    error: signedUrlError,
  } = useFetchSignedURL(
    {
      path: filePath,
      oid: orgId,
      pid,
    },
    {
      enabled: enabled && !!filePath && !!orgId && !!pid,
      placeholderData: keepPreviousData,
    },
  );
  const {
    data: fileContent,
    isPending: isFileContentPending,
    error: fileContentError,
  } = useFetchFileContent(
    {
      url: signedUrlData || '',
      filePath,
    },
    {
      enabled: !!signedUrlData,
      placeholderData: keepPreviousData,
      select,
    },
  );

  return {
    fileContent,
    isPending: isSignedUrlPending || isFileContentPending,
    error: signedUrlError || fileContentError,
  };
}
