import {
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CloseIconButton,
  MessageWithIcon,
} from '@formbio/ui';
import React from 'react';

type AlertModalProps = {
  title: string;
  message: string;
  dismissButtonText?: string;
  severity: AlertColor;
  onDismiss?: () => void;
};

export default function AlertDialog({
  alertModal,
  handleClose,
}: {
  alertModal: AlertModalProps;
  handleClose: () => void;
}) {
  return (
    <Dialog open fullWidth maxWidth='sm' onClose={handleClose}>
      <DialogTitle>
        {alertModal.title}
        <CloseIconButton aria-label='close' onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <MessageWithIcon
          severity={alertModal.severity}
          message={alertModal.message}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={handleClose}
        >
          {alertModal.dismissButtonText || 'Okay'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
