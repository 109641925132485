import { styled } from '@formbio/ui/components';
import { Divider } from '@formbio/ui/components/Layout/Divider';
import { Button } from '@formbio/ui/components/Button';
import { GoogleLogoColorIcon } from '../../Icons';

export const GOOGLE_SIGN_IN_WIDTH = '214px';

const GContainerDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: GOOGLE_SIGN_IN_WIDTH,
}));

export const SignInWithGoogleDivider = () => (
  <GContainerDivider>or</GContainerDivider>
);

const StyledButton = styled(Button, {
  shouldForwardProp: propName => propName !== 'dark',
})<{ dark?: boolean }>(({ theme, dark, variant }) => ({
  backgroundColor: dark && variant === 'contained' ? 'black' : 'white',
  color:
    dark && variant === 'contained'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main,
  ':hover': {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  ':disabled': {
    backgroundColor: theme.palette.action.disabled,
  },
}));

type Props = {
  onClick: () => void;
  loading: boolean;
  label?: string;
  dark?: boolean;
  variant?: 'contained' | 'outlined';
};

export default function SignInWithGoogle({
  onClick,
  label = 'Sign in with Google',
  loading,
  dark = false,
  variant = 'contained',
}: Props) {
  return (
    <StyledButton
      dark={dark}
      onClick={() => onClick()}
      disabled={loading}
      variant={variant}
      startIcon={<GoogleLogoColorIcon />}
    >
      {label}
    </StyledButton>
  );
}
