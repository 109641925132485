import MaintenancePage from '@/components/errors/Maintenance';
import AlertWrapper from '@/components/layouts/AlertWrapper';
import config, { featureFlagIds } from '@/config';
import { DocsContextProvider } from '@/context/docsContext';
import { GitHubAuthCtxProvider } from '@/context/gitHubAuthContext';
import MockDataContextProvider from '@/context/mockData';
import { NavigationParamsContextProvider } from '@/context/navigationContext';
import { QueryProvider } from '@/context/queryContext';
import { initializeGoogleAnalytics } from '@/hooks/analytics/useGoogleAnalytics';
import { useInitLaunchDarkly } from '@formbio/utils';
import { app } from '@/lib/firebase';
import { firaCode, inter, roobert } from '@/styles/fonts';
import { AnalyticsProvider } from '@/utils/analytics';
import createEmotionCache from '@/utils/createEmotionCache';
import { captureError } from '@/utils/logging';
import { getHref, navRoutes } from '@/utils/routes';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { api, init as initAPI } from '@formbio/api';
import { AuthContextProvider } from '@formbio/auth';
import { NotificationContextProvider } from '@formbio/notifications';
import { CssBaseline, LinearProgress, ThemeProvider } from '@formbio/ui';
import '@formbio/ui/assets/styles/global.css';
import { IconContext } from '@formbio/ui/components/Icons';
import { themeFormbio, themeFormbioDark } from '@formbio/ui/theme';
import { initDataGridProLicense } from '@formbio/ui/utils';
import { QueryParams } from '@formbio/utils';
import { getAuth, getIdToken } from 'firebase/auth';
import { KBarProvider } from 'kbar';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import includes from 'lodash/includes';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import 'regenerator-runtime/runtime.js';
import { SWRConfig } from 'swr';

initDataGridProLicense();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const swrOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  refreshInterval: undefined,
  fetcher: api.get,
};

const fontFamilies = {
  hFontFamily: roobert.style.fontFamily,
  contentFontFamily: inter.style.fontFamily,
  codeFontFamily: firaCode.style.fontFamily,
};

initAPI({
  getToken: async () => {
    const auth = getAuth(app);

    if (auth.currentUser) {
      return getIdToken(auth.currentUser);
    }
  },
  baseURL: `${config.apiUrl}/api/${config.banthaApiVersion}`,
  onError: (error: unknown) => {
    captureError({ error });
  },
  isAdmin: false,
});

function FormBio(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { ready } = useInitLaunchDarkly();
  const flags = useFlags();
  const router = useRouter();
  const timeouts = flags[featureFlagIds.sessionIdleTimeouts];

  useEffect(() => {
    // Here's a breakdown of the regular expressions:
    // - "^" asserts the position at the start of the string.
    // - "sbx\." matches the literal string "sbx.".
    // - ".+" matches one or more of any character (except for line terminators). This allows for any string to be present between "sbx." and ".google.com".
    // = "\.formbio\.com" matches the literal string ".google.com".
    // = "$" asserts the position at the end of the string.
    const sbxRegex = /^sbx\..+\.formbio\.com$/;
    const stgRegex = /^stg\..+\.formbio\.com$/;

    if (
      window.location.host === 'sbx.formbio.com' ||
      sbxRegex.test(window.location.host)
    ) {
      initializeGoogleAnalytics('G-E17Y23NCGF');
    }
    if (
      window.location.host === 'stg.formbio.com' ||
      stgRegex.test(window.location.host)
    ) {
      initializeGoogleAnalytics('G-6NCC1PWMCL');
    }

    // Want to be explicit about the GA tracking ID for production
    if (
      window.location.host === 'app.formbio.com' ||
      window.location.host === 'vectorflow.formbio.com' ||
      window.location.host === 'assays.formbio.com'
    ) {
      initializeGoogleAnalytics('G-WEJZTT3FE6');
    }
  }, []);

  const theme = useMemo(() => {
    return includes(
      [
        navRoutes.checkVerifyEmail,
        navRoutes.createAccount,
        navRoutes.forgotPassword,
        navRoutes.loginToken,
        navRoutes.login,
        navRoutes.resetPassword,
        navRoutes.revertMfa,
        navRoutes.verifyEmail,
        navRoutes.projectInvite,
        navRoutes.orgInvite,
        navRoutes.welcome,
        navRoutes.signup,
        navRoutes.verifyAndChangeEmail,
        navRoutes.recoverEmail,
      ].map(nav => nav.pathname),
      router.pathname,
    )
      ? themeFormbioDark(fontFamilies)
      : themeFormbio(fontFamilies);
  }, [router.pathname]);

  if (!ready) {
    return (
      <ThemeProvider theme={theme}>
        <LinearProgress />
      </ThemeProvider>
    );
  }
  if (flags[featureFlagIds.enableMaintenancePage]) {
    return <MaintenancePage />;
  }

  return (
    <>
      <MockDataContextProvider>
        <IconContext.Provider value={{ size: '1.25rem', weight: 'bold' }}>
          <ThemeProvider theme={theme}>
            <NotificationContextProvider>
              <QueryProvider>
                <SWRConfig value={swrOptions}>
                  <AuthContextProvider
                    app={app}
                    handleLogoutRouting={() =>
                      router.push(
                        getHref(router, navRoutes.logout, {
                          [QueryParams.destination]: router.asPath,
                        }),
                      )
                    }
                    idleTimeout={timeouts.idle_timeout}
                    promptTimeout={timeouts.prompt_timeout}
                    captureError={captureError}
                  >
                    <GitHubAuthCtxProvider>
                      <CacheProvider value={emotionCache}>
                        <AnalyticsProvider
                          apiKey={config.segmentAnalyticsApiKey}
                        >
                          <NavigationParamsContextProvider>
                            <KBarProvider>
                              <DocsContextProvider>
                                <AlertWrapper>
                                  <CssBaseline />
                                  <Component {...pageProps} />
                                </AlertWrapper>
                              </DocsContextProvider>
                            </KBarProvider>
                          </NavigationParamsContextProvider>
                        </AnalyticsProvider>
                      </CacheProvider>
                    </GitHubAuthCtxProvider>
                  </AuthContextProvider>
                </SWRConfig>
              </QueryProvider>
            </NotificationContextProvider>
          </ThemeProvider>
        </IconContext.Provider>
      </MockDataContextProvider>
    </>
  );
}

// Docs here: https://launchdarkly.github.io/react-client-sdk/
//
// Default behavior includes converting the snakeCase keys generated by LD into camelCase vars for ease of importing
// e.g. "test-flag-1" will be imported from `useFlags()` hook as "testFlag1"
export default withLDProvider({
  clientSideID: config.launchDarklyClientId!,
})(FormBio as React.ComponentType);
