import { useActionCode } from '@formbio/auth';
import { useNotificationContext } from '@formbio/notifications';
import { Skeleton, Spacer } from '@formbio/ui';
import { Description, Title } from '@formbio/ui/components/Layout/Auth';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes } from 'firebase/auth';
import { ReactNode, useEffect, useState } from 'react';
import { UrlObject } from 'url';
import BackToSignIn from '../BackToSignIn';

// types
type Props = {
  code: string;
  successMessage: string | ReactNode;
  failedMessage: string;
  onSuccess: () => void;
  backToSignInHref: UrlObject;
};

const VerifyCode = ({
  code,
  successMessage,
  failedMessage,
  onSuccess,
  backToSignInHref,
}: Props) => {
  const { loading: isActionCodeLoading, success, error } = useActionCode(code);
  const [content, setContent] = useState({
    title: '',
    description: '',
  });

  const { setSnackbar } = useNotificationContext();

  // show success msg
  useEffect(() => {
    if (success) {
      // redirect to log in page and show success msg
      setSnackbar({
        autoHideDuration: 5000,
        variant: 'success',
        children: successMessage,
      });
      onSuccess();
    }
  }, [success]);

  // error handling
  useEffect(() => {
    if (error && error instanceof FirebaseError) {
      switch (error.code) {
        case AuthErrorCodes.EXPIRED_OOB_CODE:
        case AuthErrorCodes.INVALID_OOB_CODE:
          setContent({
            title: 'Your link has expired',
            description: failedMessage,
          });
          break;
        case AuthErrorCodes.USER_DELETED:
        case AuthErrorCodes.USER_DISABLED:
          setContent({
            title: 'User is disabled',
            description: `Contact an organization admin to enable your account.`,
          });
          break;
        default:
          setContent({
            title: 'Something went wrong',
            description: `Contact an support team to resolve this issue.`,
          });
          break;
      }
    }
  }, [error]);

  return isActionCodeLoading ? (
    <>
      <Title>
        <Skeleton />
      </Title>
      <Description>
        <Skeleton />
        <Skeleton />
      </Description>
      <Spacer size='m' />
      <Skeleton variant='rounded' height={44} width={150} />
    </>
  ) : (
    <>
      <Title>{content.title}</Title>
      <Description>{content.description}</Description>
      {error && (
        <>
          <Spacer size='xxl' />
          <BackToSignIn nextHref={backToSignInHref} />
        </>
      )}
    </>
  );
};

export default VerifyCode;
