import { debounce } from 'lodash';
import { MutableRefObject, useEffect } from 'react';

export default function useDebouncedResizeObserver(
  ref: MutableRefObject<HTMLButtonElement | HTMLElement | null>,
  callback: () => void,
  debounceLength = 200,
) {
  useEffect(() => {
    const debouncedOnResize = debounce(entries => {
      if (entries[0]) {
        callback();
      }
    }, debounceLength);

    const resizeObserver = new ResizeObserver(debouncedOnResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current!);
      }
    };
  }, [callback]);
}
