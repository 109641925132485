import { Title, Description } from '@formbio/ui/components/Layout/Auth';
import { useResetPassword } from '@formbio/auth';
import {
  Stack,
  Typography,
  NextMuiLink,
  Button,
  styled,
  AuthErrorMessage,
  FormPasswordItem,
} from '@formbio/ui';
import { useForm } from 'react-hook-form';
import Form from '../Form';
import { ArrowLeft as ArrowLeftIcon } from '@formbio/ui/components/Icons';
import { getPasswordValidator } from '@formbio/ui/utils/yupHelper';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UrlObject } from 'url';

type FormData = {
  newPassword: string;
  confirmedPassword: string;
};

type Props = {
  code?: string;
  loginHref: UrlObject;
  submitHandler?: (email?: string) => void;
};

const schema = yup
  .object({
    newPassword: getPasswordValidator().required('Please enter new password'),
    confirmedPassword: yup
      .string()
      .required('Please confirm password')
      .oneOf([yup.ref('newPassword'), ''], 'Must match Password'),
  })
  .required();

const StyledArrowLeftIcon = styled(ArrowLeftIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export default function ResetPasswordForm({
  code,
  loginHref,
  submitHandler,
}: Props) {
  const { request, loading, error, success, email } = useResetPassword(code);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ newPassword }: FormData) => {
    submitHandler?.(email);
    return request(newPassword);
  };

  if (error) {
    return <AuthErrorMessage error={error} />;
  }

  if (success) {
    return (
      <NextMuiLink nextHref={loginHref} underline='none'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <StyledArrowLeftIcon />
          <Typography color='secondary'>
            Your password has been changed and you can now sign in.
          </Typography>
        </Stack>
      </NextMuiLink>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Title>Change Password</Title>
      <Description>
        Your new password must contain a minimum of 6 characters.
      </Description>
      <input
        aria-hidden='true'
        type='hidden'
        defaultValue={email}
        autoComplete='username'
        autoCapitalize='off'
        name='username'
        disabled
      />
      <FormPasswordItem
        label='Password'
        placeholder='Enter Password'
        required
        registerProps={register('newPassword')}
        disabled={loading}
        errorMessage={errors.newPassword?.message}
        inputProps={{
          autoComplete: 'new-password',
        }}
      />
      <FormPasswordItem
        label='Confirm Password'
        placeholder='Enter Password'
        required
        registerProps={register('confirmedPassword')}
        disabled={loading}
        errorMessage={errors.confirmedPassword?.message}
        inputProps={{
          autoComplete: 'new-password',
        }}
      />
      <Button
        variant='contained'
        color='secondary'
        type='submit'
        disabled={loading}
      >
        Confirm
      </Button>
    </Form>
  );
}
