/**
 * A template component for a styled popup menu
 */

import React, { ReactNode } from 'react';
import { bindMenu, bindTrigger, Box, PopupState, PopupStateType } from '..';
import { Menu } from '../Menu';

export default function MenuPopup({
  button,
  menuItems,
  popupState,
}: {
  button?: ReactNode;
  menuItems: ReactNode;
  popupState: PopupStateType;
}) {
  return (
    <PopupState variant='popover' popupId={popupState.popupId}>
      {() => (
        <React.Fragment>
          {button && <Box {...bindTrigger(popupState)}>{button}</Box>}
          <Menu
            {...bindMenu(popupState)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            MenuListProps={{ autoFocus: true }}
          >
            {menuItems}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
