export function downloadStringifiedJson({
  stringifiedJson,
  fileName = 'data',
}: {
  stringifiedJson: string;
  fileName?: string;
}) {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    stringifiedJson,
  )}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = `${fileName}.json`;

  link.click();

  link.remove();
}

/**
 * More flexible version of downloadStringifiedJson
 * to handle any text content.
 *
 * Downloading a file directly from GCS doesn't provide the correct file extension (defaults to .txt).
 *
 * This function uses the file content retrieved from the GCS signed url to recreated a file with the correct extension.
 */
export function downloadTextContent({
  textContent,
  fileName,
}: {
  textContent: string;
  fileName: string;
}) {
  const text = `data:text/plain;chatset=utf-8,${encodeURIComponent(
    textContent,
  )}`;
  const link = document.createElement('a');
  link.href = text;
  link.download = fileName;

  link.click();

  link.remove();
}
