import { styled } from '@mui/material';
import { useState } from 'react';
import { Chip } from '..';
import { Typography } from '../../Typography';

const StyledChip = styled(Chip)(({ theme }) => ({
  textTransform: 'none',
  background: 'none',
  border: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.text.primary,
  '.MuiChip-deleteIcon': {
    margin: theme.spacing(0.5, 0),
  },
}));

export default function DataLabelChip({
  label,
  labelType,
  onDelete,
}: {
  label: string;
  labelType: string;
  onDelete?: (event: unknown) => void;
}) {
  const noValue = '<NO_VALUE>';
  const [isDisabled, setIsDisabled] = useState(false);

  function handleOnDelete(event: unknown) {
    setIsDisabled(true);
    if (onDelete) {
      onDelete(event);
    }
  }

  return (
    <StyledChip
      disabled={isDisabled}
      onDelete={onDelete && handleOnDelete}
      size='small'
      label={
        <Typography variant='caption1'>{`${labelType || noValue}: ${
          label || noValue
        }`}</Typography>
      }
    />
  );
}
