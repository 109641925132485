import { Genome, GenomesVisibility } from '@formbio/api';
import { AutocompleteRenderInputParams, Popper, styled } from '@mui/material';
import { orderBy } from 'lodash';
import { HTMLAttributes, SyntheticEvent, useState } from 'react';
import { Typography } from '../Typography';
import { CircularProgress } from '../CircularProgress';
import { TextField } from '../TextField';
import { Autocomplete } from '../Autocomplete';

const groupBy = (option: Genome): 'Public' | 'Private' | 'From File' => {
  if (option.visibility) {
    return option.visibility === GenomesVisibility.PUBLIC
      ? 'Public'
      : option.visibility === GenomesVisibility.FROM_FILE
        ? 'From File'
        : 'Private';
  } else {
    return option.custom ? 'Private' : 'Public';
  }
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

const StyledGroup = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: theme.spacing(1),
  fontWeight: theme.typography.h4?.fontWeight,
  fontSize: theme.typography.h4?.fontSize,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grey[50],
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

const StyledGroupItems = styled('ul')({
  padding: 0,
});

const StyledOption = styled('li')(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: theme.spacing(1),
}));

const StyledOptionLabel = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.25),
}));

const AutocompleteRenderInput: React.FC<{
  params: AutocompleteRenderInputParams;
  loading: boolean;
}> = ({ params, loading }) => {
  const inputProps = loading
    ? {
        InputProps: {
          ...params.InputProps,
          endAdornment: <CircularProgress size={'1rem'} />,
        },
      }
    : {};

  return (
    <TextField
      {...params}
      placeholder={loading ? 'Loading Genomes' : 'Search Genomes'}
      {...inputProps}
      size='small'
    />
  );
};

interface GenomesDropdownProps<T extends Genome> {
  id?: string;
  loading?: boolean;
  genomes: T[];
  reference: T | undefined;
  setReference: (reference: T) => void;
  disabled?: boolean;
}

export default function GenomesDropdown<T extends Genome>({
  id = 'reference-autocomplete',
  loading = false,
  genomes,
  reference,
  setReference,
  disabled,
}: GenomesDropdownProps<T>) {
  const [input, setInput] = useState('');

  const sortedGenomes = orderBy(
    genomes,
    [
      genome => {
        const group = groupBy(genome);
        return group === 'From File' ? 0 : group === 'Private' ? 1 : 2;
      },
      genome => genome.commonName?.toLowerCase(),
      genome => genome.id.toLowerCase(),
    ],
    ['asc', 'asc'],
  );

  const handleReferenceSelectChange = (
    _event: SyntheticEvent<Element, Event>,
    value: T | null,
  ) => {
    value && setReference(value);
  };

  const handleInputChange = (
    _event: SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    setInput(value);
  };

  return (
    <Autocomplete
      autoHighlight
      id={id}
      getOptionLabel={option =>
        option.commonName || `${option.name} (${option.id})`
      }
      groupBy={groupBy}
      noOptionsText={`No matches found for "${input}"`}
      onChange={handleReferenceSelectChange}
      onInputChange={handleInputChange}
      options={sortedGenomes}
      PopperComponent={StyledPopper}
      disabled={disabled}
      renderInput={params => (
        <AutocompleteRenderInput params={params} loading={loading} />
      )}
      renderGroup={params => (
        <li key={params.key}>
          <StyledGroup>{params.group}</StyledGroup>
          <StyledGroupItems>{params.children}</StyledGroupItems>
        </li>
      )}
      renderOption={(props, option) => {
        // somehow props contains a `key` property that is not in the type
        // spreading `key` triggers a console error so we have to extract it
        // manually
        const { key, ...other } = props as HTMLAttributes<HTMLLIElement> & {
          key: string;
        };
        return (
          <StyledOption key={key} {...other}>
            <StyledOptionLabel>
              {option.commonName || `${option.name} (${option.id})`}
            </StyledOptionLabel>
          </StyledOption>
        );
      }}
      value={reference || null}
    />
  );
}
