import { ReactNode } from 'react';
import { UrlObject } from 'url';
import { Tooltip } from '../../Tooltip';
import { MenuItem } from '../../Menu';
import { styled } from '@mui/material';
import NextMuiLink from '../../Link/NextMuiLink';

interface MenuItemProps {
  component?: typeof NextMuiLink;
  nextHref?: UrlObject;
}

const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1, 1, 1, 0.5),
  '&:hover': {
    backgroundColor: theme.palette.primary[800],
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.primary[600],
  },
}));

export default function OrgProjectMenuItem({
  selected,
  children,
  onClick,
  nextHref,
  disabled,
}: {
  selected: boolean;
  children: ReactNode;
  onClick?: () => void;
  nextHref?: UrlObject;
  disabled?: boolean;
}) {
  if (disabled) {
    return (
      <Tooltip title='This org has no projects'>
        <span>
          <StyledMenuItem disabled>{children}</StyledMenuItem>
        </span>
      </Tooltip>
    );
  }
  if (nextHref) {
    // project menu item
    return (
      <StyledMenuItem
        onClick={onClick}
        selected={selected}
        component={NextMuiLink}
        nextHref={nextHref}
      >
        {children}
      </StyledMenuItem>
    );
  }
  // org menu item
  return (
    <StyledMenuItem onClick={onClick} selected={selected}>
      {children}
    </StyledMenuItem>
  );
}
