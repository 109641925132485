import React, { ReactNode } from 'react';
import { Grid, Box, styled, useTheme } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import NextMuiLink from '@formbio/ui/components/Link/NextMuiLink';
import { AppLogo, LogoColors } from '@formbio/ui/components/AppLogo';

import lightBackground from '../../../assets/images/bg/light.jpg';
import darkBackground from '../../../assets/images/bg/dark.jpg';
import { hexToRGBA } from '@formbio/ui/utils';
import get from 'lodash/get';

/**
 * styled
 */
const BackgroundWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary[900],
}));

const BackgroundGradient = styled('div')(({ theme }) => {
  const primary50 = get(theme, 'palette.primary.50', '#fff');
  const primary900 = get(theme, 'palette.primary.900', '#000');
  return {
    background:
      theme.palette && theme.palette.mode === 'dark'
        ? `linear-gradient(90deg, ${hexToRGBA(primary900, 1)} 0%, ${hexToRGBA(
            primary900,
            0.97,
          )} 70%, ${hexToRGBA(primary900, 0)} 99%);`
        : `linear-gradient(90deg, ${hexToRGBA(primary50, 1)} 0%, ${hexToRGBA(
            primary50,
            0.57,
          )} 70%, ${hexToRGBA(primary50, 0)} 99%);`,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
  };
});

const BackgroundImage = styled('div')(({ theme }) => ({
  backgroundImage: `url(${
    theme.palette.mode === 'dark' ? darkBackground.src : lightBackground.src
  })`,
  backgroundPosition: 'unset',
  backgroundRepeat: 'no-repeat',
  backgroundSize: theme.palette.mode === 'light' ? 'cover' : 'contain',
  backgroundPositionX: 'right',
  height: '100vh',
  backgroundColor: theme.palette.primary[900],
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
}));

const StyledNavigationContainer = styled('p')(({ theme }) => ({
  textAlign: 'left',
  paddingTop: theme.spacing(4),
  color: theme.palette.primary[50],
}));

const AuthLayoutGridContainer = styled(Grid)({
  minHeight: '100vh',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  zIndex: 3,
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  marginBottom: theme.spacing(1),
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  textAlign: 'start',
  marginBottom: theme.spacing(3),
}));

const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  maxWidth: 'sm',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const StyledBoxItem = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(8),
}));

const AppLogoWrapper = styled(AppLogo)({
  paddingLeft: 0,
});

/**
 * page title: h1
 */
export function Title(
  props: React.ComponentPropsWithoutRef<typeof Typography>,
) {
  return <StyledHeader variant='h1' {...props} />;
}

export function Description(
  props: React.ComponentPropsWithoutRef<typeof Typography>,
) {
  return <StyledTypography {...props} />;
}

type Props = {
  children: ReactNode;
  navigation?: ReactNode;
  homePathname?: string;
};

export default function AuthLayout({
  children,
  navigation,
  homePathname,
}: Props) {
  const theme = useTheme();
  return (
    <>
      <BackgroundWrapper>
        <BackgroundGradient />
        <BackgroundImage />
      </BackgroundWrapper>
      <AuthLayoutGridContainer container>
        <Grid item xs={12} md={10} lg={6} xl={4}>
          <StyledBoxWrapper>
            <StyledBoxItem>
              <NextMuiLink nextHref={homePathname ?? '/'}>
                <AppLogoWrapper
                  logoColor={
                    theme.palette.mode === 'dark'
                      ? LogoColors.white
                      : LogoColors.black
                  }
                />
              </NextMuiLink>
            </StyledBoxItem>
            {children}
            {navigation && (
              <StyledNavigationContainer>
                {navigation}
              </StyledNavigationContainer>
            )}
          </StyledBoxWrapper>
        </Grid>
      </AuthLayoutGridContainer>
    </>
  );
}
