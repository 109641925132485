import { isAxiosError, useVerificationEmail } from '@formbio/api';
import { useActionCode, useAuth } from '@formbio/auth';
import {
  useNotificationContext,
  useOnNetworkError,
} from '@formbio/notifications';
import {
  LinearProgressFullWidth,
  Skeleton,
  Spacer,
  Typography,
  styled,
  useCountDown,
} from '@formbio/ui';
import { Description, Title } from '@formbio/ui/components/Layout/Auth';
import { pluralize } from '@formbio/ui/utils';
import { useEffect } from 'react';
import AuthButton from '../AuthButton';
import useVerifyEmailMessage from '../../hooks/useVerifyEmailMessage';

type Props = {
  code?: string;
  hideNetworkErrors?: boolean;
  redirectHome?: () => void;
  redirectToLogin?: () => void;
};

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  '.padding': {
    marginTop: theme.spacing(3),
  },
}));

const RETRY_DELAY = 60;
// TODO for now we are not using hideNetworkErrors
// because we'll want to extract auth components to a separate package
// which will not have access to LaunchDarkly (where hideNetwrorkErrors is coming from)
const VerifyEmail = ({
  code,
  hideNetworkErrors,
  redirectHome,
  redirectToLogin,
}: Props) => {
  const { user, loading: isUserLoading } = useAuth();
  const onNetWorkError = useOnNetworkError(hideNetworkErrors);
  const {
    mutate,
    isSuccess,
    isPending: isLoading,
  } = useVerificationEmail({
    onError: onNetWorkError,
  });
  const {
    loading: isVerificationLoading,
    success: successfulVerification,
    error: errorVerification,
  } = useActionCode(code);
  const { title, line1, line2, updateContentOnSuccess } = useVerifyEmailMessage(
    {
      errorCode: errorVerification?.code,
      userEmail: user?.email,
    },
  );
  const { setSnackbar } = useNotificationContext();
  const {
    counter,
    isRunning: isCounterRunning,
    startCount,
  } = useCountDown(RETRY_DELAY);

  // already verified
  useEffect(() => {
    if (user && user.emailVerified) {
      alreadyVerifiedAccount();
    }
  }, [user]);

  // successfully verified
  useEffect(() => {
    if (successfulVerification) {
      // redirect to log in page and show success msg
      setSnackbar({
        autoHideDuration: 5000,
        variant: 'success',
        children: (
          <>
            <strong>Email Verified</strong>
            <Spacer size='xs' />
            You successfully verified your email
          </>
        ),
      });

      redirectUser();
    }
  }, [successfulVerification]);

  async function redirectUser() {
    await user?.reload();
    user?.email ? redirectHome?.() : redirectToLogin?.();
  }

  // resend
  async function handleSendEmailVerification() {
    if (user && user.email) {
      mutate(
        {
          email: user.email,
        },
        {
          onSuccess: data => {
            if (data.status === 202) {
              setSnackbar({
                variant: 'success',
                children: (
                  <>
                    <strong>Verification email sent</strong>
                    <Spacer size='xs' />
                    Check your emails for a link to verify your account.
                  </>
                ),
              });
              updateContentOnSuccess();
            } else {
              alreadyVerifiedAccount();
            }
          },
          onError: (error: unknown) => {
            if (isAxiosError(error) && error.response?.status === 429) {
              startCount();
              setSnackbar({
                variant: 'error',
                children: `Too many attempts. Please wait ${RETRY_DELAY} sec before retrying.`,
              });
            } else {
              setSnackbar({
                variant: 'error',
                children: 'Something went wrong. Please try again later.',
              });
            }
          },
        },
      );
    } else {
      // redirect to log in page
      redirectToLogin?.();
    }
  }

  const alreadyVerifiedAccount = () => {
    setSnackbar({
      autoHideDuration: 5000,
      variant: 'info',
      children: (
        <>
          <strong>Your email has been already verified</strong>
          <Spacer size='xs' />
          You’ve already verified your account.
        </>
      ),
    });
    redirectHome?.();
  };

  return isVerificationLoading || isUserLoading || user?.emailVerified ? (
    <>
      <Title>
        <Skeleton />
      </Title>
      <Description>
        <Skeleton />
        <Skeleton />
      </Description>
      <Spacer size='m' />
      <Skeleton variant='rounded' height={44} width={150} />
    </>
  ) : (
    <>
      {!successfulVerification && (
        <>
          <Title>{title}</Title>
          <Description>
            {line1}
            <br />
            {line2}
          </Description>
          <Spacer size='m' />
          <StyledDiv>
            <AuthButton
              onClick={handleSendEmailVerification}
              disabled={isLoading || isSuccess || isCounterRunning}
            >
              {user ? 'Resend email' : 'Login to resend verification link'}
            </AuthButton>
            {isCounterRunning && (
              <Typography
                variant='caption'
                color='textSecondary'
                className='padding'
              >
                You can resend the email in&nbsp;
                {pluralize({
                  number: counter,
                  singular: 'second',
                  plural: 'seconds',
                })}
              </Typography>
            )}
          </StyledDiv>
        </>
      )}
      {isLoading && (
        <>
          <Spacer size='m' />
          <LinearProgressFullWidth />
        </>
      )}
    </>
  );
};

export default VerifyEmail;
