import { usePasswordReset } from '@formbio/api';
import { useOnNetworkError } from '@formbio/notifications';
import { FormInputItem, Spacer, Stack } from '@formbio/ui';
import { Description, Title } from '@formbio/ui/components/Layout/Auth';
import { getEmailValidator } from '@formbio/ui/utils/yupHelper';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { UrlObject } from 'url';
import * as yup from 'yup';
import AuthButton from '../AuthButton';
import BackToSignIn from '../BackToSignIn';
import Form from '../Form';

type FormData = {
  email: string;
};

const schema = yup
  .object({
    email: getEmailValidator(
      'Example of a valid email: myemail@domain.com',
    ).required('Please enter an email address'),
  })
  .required();

// TODO for now we are not using hideNetworkErrors
// because we'll want to extract auth components to a separate package
// which will not have access to LaunchDarkly (where hideNetworkErrors is coming from)
export default function ForgotPasswordForm({
  hideNetworkErrors,
  backToSignInHref,
  onSubmitHandler,
}: {
  hideNetworkErrors?: boolean;
  backToSignInHref: UrlObject;
  onSubmitHandler?: (email: string) => void;
}) {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const onNetWorkError = useOnNetworkError(hideNetworkErrors);
  const { mutate, isPending: isLoading } = usePasswordReset({
    onError: onNetWorkError,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FormData>({ resolver: yupResolver(schema) });

  const onSubmit = async ({ email }: FormData) => {
    onSubmitHandler?.(email);
    mutate(
      {
        email: email,
      },
      {
        onSuccess: () => {
          setIsSubmitSuccessful(true);
        },
      },
    );
  };

  if (isSubmitSuccessful) {
    return (
      <Stack spacing={1}>
        <Title>Check your Email</Title>
        <Description variant='h4'>We have sent you an email</Description>
        <Description color='primary.500'>
          We sent an email to <strong>{watch('email')}</strong>, which contains
          a link to reset your Form Bio password.
        </Description>
        <BackToSignIn nextHref={backToSignInHref} />
      </Stack>
    );
  }

  return (
    <>
      <Title>Reset Password</Title>

      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormInputItem
          label='Email'
          placeholder='Enter Email'
          type='email'
          registerProps={register('email')}
          errorMessage={errors.email?.message}
          required
          disabled={isLoading}
          description={
            <Description color='primary.500'>
              Please enter your email address. <br />
              We will send you an email to reset your password.
            </Description>
          }
        />

        <AuthButton type='submit' disabled={isLoading}>
          Send Email
        </AuthButton>
        <Spacer size='s' />
        <BackToSignIn nextHref={backToSignInHref} />
      </Form>
    </>
  );
}
