import { FunctionComponent } from 'react';
import { Cube as CubeIcon } from '@formbio/ui/components/Icons';
import { PaletteColor, useTheme } from '@mui/material';

interface IColorBackgroundAvatar {
  backgroundColor: string;
  textColor: string;
  Icon: FunctionComponent;
}

function useDefaultColorAvatar(): IColorBackgroundAvatar {
  const theme = useTheme();

  return {
    backgroundColor: theme.palette.info[100] || theme.palette.info.light,
    textColor: theme.palette.info[700] || theme.palette.info.dark,
    Icon: CubeIcon,
  };
}

function getColors(color?: PaletteColor) {
  if (color) {
    return {
      backgroundColor: color.light,
      textColor: color.dark,
    };
  }
  return {};
}

type ColorBackgroundAvatarItem = Pick<IColorBackgroundAvatar, 'Icon'> & {
  color?: PaletteColor;
};

export default function useColorBackgroundAvatarItem(
  item?: ColorBackgroundAvatarItem,
) {
  const defaultAvatar = useDefaultColorAvatar();

  return {
    ...defaultAvatar,
    ...item,
    ...getColors(item?.color),
  };
}
