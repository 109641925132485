import useUrlParams from '@formbio/utils';
import {
  ALL_USAGE_FEATURES,
  FeatureNames,
  FeatureUsage,
  useAccountQuotasUsage,
} from '@formbio/api';

/**
 * This is the useQuotas that evals numeric usage hook.
 * It fetches the usage and quota for a limited number of features and checks if the usage is less than the quota.
 */

export enum TotalsFeatureNames {
  TOTAL_USER_SEATS = 'total_user_seats',
}

function isTotalsFeature(
  feature: FeatureNames | TotalsFeatureNames,
): feature is TotalsFeatureNames {
  return Object.values<string>(TotalsFeatureNames).includes(feature);
}

export function useAllowBlockNumericQuotas() {
  const { orgId } = useUrlParams();
  const {
    data: numericQuotas,
    isPending,
    error,
  } = useAccountQuotasUsage(orgId, ALL_USAGE_FEATURES);
  const getUsageQuotaTotal = useUsageQuotaTotals(numericQuotas);

  function getNumericQuota(feature: FeatureNames | TotalsFeatureNames) {
    if (isTotalsFeature(feature)) {
      return getUsageQuotaTotal(feature);
    }
    return numericQuotas?.find((feat) => feat.name === feature);
  }

  function passesNumericQuota(feature: FeatureNames | TotalsFeatureNames) {
    const foundFeature = getNumericQuota(feature);
    return !foundFeature || foundFeature.quotaUsed < foundFeature.totalQuota;
  }

  return {
    getNumericQuota,
    passesNumericQuota,
    numericQuotas,
    isPending,
    error,
  };
}

export interface TotalsFeatureUsage
  extends Omit<FeatureUsage, 'name' | 'id' | 'quota'> {
  name: TotalsFeatureNames;
}

function useUsageQuotaTotals(quotas?: FeatureUsage[] | null) {
  return (feature: TotalsFeatureNames): TotalsFeatureUsage | undefined => {
    if (quotas) {
      switch (feature) {
        case TotalsFeatureNames.TOTAL_USER_SEATS:
          return getTotalUsersUsage(quotas);
        default:
          return;
      }
    }
    return;
  };
}

function getTotalUsersUsage(
  quotas: FeatureUsage[],
): TotalsFeatureUsage | undefined {
  const includedSeats = quotas.find(
    (feat) => feat.name === FeatureNames.INCLUDED_USER_SEATS,
  );
  const additionalSeats = quotas.find(
    (feat) => feat.name === FeatureNames.ADDITIONAL_USER_SEATS,
  );
  if (includedSeats && additionalSeats) {
    const totalUsersUsed = includedSeats.quotaUsed + additionalSeats.quotaUsed;
    const totalUsersAvailable =
      includedSeats.totalQuota + additionalSeats.totalQuota;
    return {
      name: TotalsFeatureNames.TOTAL_USER_SEATS,
      displayName: 'Total User Seats',
      description: 'The total number of user seats available to the account.',
      totalQuota: totalUsersAvailable,
      quotaUsed: totalUsersUsed,
      timeStart: '',
      timeEnd: '',
    };
  }
  return;
}
