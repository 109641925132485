import { useSignInWithGoogle } from '@formbio/auth';
import SignInWithGoogle from '@formbio/ui/components/Button/SignInWithGoogle';
import { User } from 'firebase/auth';
import { useEffect } from 'react';

type Props = {
  onSuccess?: (user: User) => void;
  label?: string;
  dark?: boolean;
  disabled?: boolean;
  variant?: 'contained' | 'outlined';
};

export default function SignInWithGoogleWrapper({
  onSuccess,
  label = 'Sign in with Google',
  dark,
  disabled,
  variant,
}: Props) {
  const { request, user, loading } = useSignInWithGoogle();

  useEffect(() => {
    if (user) {
      onSuccess?.(user);
    }
  }, [user]);

  return (
    <SignInWithGoogle
      onClick={() => request()}
      loading={loading || !!disabled}
      label={label}
      dark={dark}
      variant={variant}
    />
  );
}
