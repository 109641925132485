import MFACodeDialog from '../components/MFACodeDialog';
import ReAuthDialog from '../components/ReAuthDialog';
import { useNotificationContext } from '@formbio/notifications';
import { LoadingPageProgress, SessionIdleDialog } from '@formbio/ui';
import { FirebaseApp, FirebaseError } from 'firebase/app';
import { Auth, getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { createContext, ReactNode, useEffect, useState } from 'react';

export type AuthUser = User;

type AuthContextType = {
  auth: Auth;
  user: AuthUser | null;
  loading: boolean;
  // handle re-authentication
  reAuth: () => void;
  isAuthRecent: boolean;
  // handle mfa
  verifyMFA: (error: FirebaseError) => void;
  isMfaSuccess: boolean;
};

const AuthContext = createContext({} as AuthContextType);

export function AuthContextProvider({
  app,
  children,
  handleLogoutRouting,
  idleTimeout,
  promptTimeout,
  captureError,
}: {
  app: FirebaseApp;
  children: ReactNode;
  handleLogoutRouting?: () => void;
  idleTimeout?: number;
  promptTimeout?: number;
  captureError: ({
    name,
    error,
    context,
  }: {
    name: string;
    error: unknown;
    context: { [key: string]: unknown } | null | undefined;
  }) => void;
}) {
  const auth = getAuth(app);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<AuthUser | null>(null);
  const { setSnackbar } = useNotificationContext();
  // re-auth
  const [isReAuthVisible, setIsReAuthVisible] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(false);
  const [isAuthRecent, setIsAuthRecent] = useState(false);
  // mfa
  const [isMFAVisible, setIsMFAVisible] = useState(false);
  const [mfaError, setMFAError] = useState<FirebaseError | undefined>(
    undefined,
  );
  const [isMfaSuccess, setIsMfaSuccess] = useState(false);

  useEffect(() => {
    const listener = onAuthStateChanged(auth, async user => {
      setLoading(false);
      setUser(user);
    });

    return () => {
      listener();
    };
  }, [auth]);

  const reAuth = () => {
    setIsAuthRecent(false);
    setIsReAuthVisible(true);
  };

  const verifyMFA = (error: FirebaseError) => {
    setIsMFAVisible(true);
    setMFAError(error);
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        loading,
        reAuth,
        isAuthRecent,
        verifyMFA,
        isMfaSuccess,
      }}
    >
      {children}

      {user && (
        <SessionIdleDialog
          idleTimeout={idleTimeout}
          promptTimeout={promptTimeout}
          logout={inactive => {
            if (inactive) {
              setSnackbar({
                variant: 'info',
                children: 'Your session has expired. Please sign in again.',
                autoHideDuration: 150000,
              });
            }
            handleLogoutRouting?.();
          }}
        />
      )}

      {isCheckingAuth && <LoadingPageProgress />}
      {/* user need to re-auth (non SSO users only) */}
      {isReAuthVisible && (
        <ReAuthDialog
          onSuccess={() => {
            setIsReAuthVisible(false);
            setIsAuthRecent(true);
          }}
          onClose={() => setIsReAuthVisible(false)}
          onLoading={status => setIsCheckingAuth(status)}
          app={app}
          captureError={captureError}
        />
      )}
      {/* user need to verify MFA */}
      {isMFAVisible && (
        <MFACodeDialog
          open
          onClose={() => setIsMFAVisible(false)}
          onSuccess={() => {
            setIsMFAVisible(false);
            setIsMfaSuccess(true);
          }}
          raisedError={mfaError}
          handleLogoutRouting={handleLogoutRouting}
          captureError={captureError}
        />
      )}
    </AuthContext.Provider>
  );
}

export default AuthContext;
