import { DEFAULT_MARGIN } from '@formbio/ui/theme/constants';
import { Typography } from '@formbio/ui/components/Typography';
import { styled, Variant } from '@formbio/ui/components';
import { ReactNode } from 'react';

const StyledTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'noMargin',
})<{ noMargin?: boolean }>(({ noMargin }) => ({
  marginTop: noMargin ? 0 : DEFAULT_MARGIN,
}));

export default function Header({
  children,
  variant,
  noMargin = false,
}: {
  children: ReactNode;
  variant: Variant;
  noMargin?: boolean;
}) {
  return (
    <StyledTypography variant={variant} noMargin={noMargin}>
      {children}
    </StyledTypography>
  );
}
