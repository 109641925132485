import { useRef, useState } from 'react';
import { LinearProgress } from '../LinearProgress';
import { styled } from '@mui/material';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  paddingBottom: theme.spacing(4),
  '.iframe': {
    border: 'none',
  },
}));

export default function DynamicIFrame({
  src,
  height,
}: {
  src: string;
  height: number;
}) {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const onIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      <StyledContainer>
        <iframe
          className='iframe'
          ref={ref}
          src={src}
          allowFullScreen={true}
          onLoad={onIframeLoad}
          height={height}
        />
      </StyledContainer>
    </>
  );
}
