import { FormInputItem } from '@formbio/ui';
import {
  getEmailValidator,
  getPasswordValidator,
} from '@formbio/ui/utils/yupHelper';
import {
  Divider,
  IconButton,
  styled,
  ErrorMessage,
  AuthErrorMessage,
} from '@formbio/ui';
import {
  At as AtIcon,
  EyeClosed as EyeClosedIcon,
  Eye as EyeIcon,
  Key as KeyIcon,
} from '@formbio/ui/components/Icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthError } from 'firebase/auth';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Form from '../Form';

export type LoginFormData = {
  email: string;
  password: string;
};

const StyledForm = styled(Form)(({ theme }) => ({
  minWidth: '600px',
  '.start-adornment': {
    display: 'flex',
    gap: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const schema = yup
  .object({
    email: getEmailValidator(
      'Example of a valid email: myemail@domain.com',
    ).required('Please enter an email address'),
    password: getPasswordValidator().required(),
  })
  .required();

export default function LoginAssayForm({
  id,
  isDisabled,
  onSubmit,
  error,
  accountError,
}: {
  id: string;
  isDisabled?: boolean;
  onSubmit: ({ email, password }: LoginFormData) => void;
  error?: AuthError | Error;
  accountError: string;
}) {
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate id={id}>
      <FormInputItem
        label='Email'
        placeholder='Enter Email'
        type='email'
        registerProps={register('email')}
        errorMessage={errors.email?.message}
        required
        size='small'
        disabled={isDisabled}
        InputProps={{
          startAdornment: (
            <div className='start-adornment'>
              <AtIcon color='gray' />
              <Divider orientation='vertical' flexItem />
            </div>
          ),
        }}
      />
      <FormInputItem
        label='Password'
        placeholder='Enter Password'
        type={showPassword ? 'text' : 'password'}
        registerProps={register('password')}
        errorMessage={errors.password?.message}
        required
        disabled={isDisabled}
        InputProps={{
          startAdornment: (
            <div className='start-adornment'>
              <KeyIcon color='gray' />
              <Divider orientation='vertical' flexItem />
            </div>
          ),
          endAdornment: (
            <IconButton
              disabled={isDisabled}
              onClick={() => setShowPassword(showPassword => !showPassword)}
            >
              {showPassword ? <EyeIcon /> : <EyeClosedIcon />}
            </IconButton>
          ),
        }}
        size='small'
      />
      {error && <AuthErrorMessage error={error} />}
      {accountError && <ErrorMessage>{accountError}</ErrorMessage>}
    </StyledForm>
  );
}
