export enum QueryParams {
  orgId = 'orgId',
  pid = 'pid',
  runId = 'runId',
  groupKey = 'groupKey',
  email = 'email',
  mode = 'mode',
  oobCode = 'oobCode',
  // TODO: Remove runName after creating step-based workflow builder
  runName = 'runName',
  // for search box queries
  search = 'search',
  // for keeping track of which tab is selected
  tab = 'tab',
  // for searching users
  users = 'users',
  // path for file management directory
  path = 'path',
  // destination page after login
  destination = 'destination',
  // workflow category picked by user
  category = 'category',
  status = 'status',
  previousPageParams = 'previousPageParams',
  // name of repo for a workflow
  repoName = 'repoName',
  workflowName = 'workflowName',
  // workflow version
  version = 'version',
  // uploaded org and proj indicate those to which a workflow is uploaded to
  uploadedOrg = 'uploadedOrg',
  uploadedProj = 'uploadedProj',
  // sign up invitation id (invite token)
  invitationId = 'invitationId',
  // view: organizations/{org}/projects/{proj}/members
  // param: changing tab
  memberType = 'memberType',
  referenceId = 'referenceId',
  encodedFiles = 'encodedFiles',
  workflowId = 'workflowId',
  // firebase
  apiKey = 'apiKey',
  lang = 'lang',
  checkSuiteId = 'checkSuiteId',
  journeyId = 'journeyId',
  phaseRunId = 'phaseId',
  stepRunId = 'stepId',
  journeyRunId = 'journeyRunId',
  download = 'download',
  formBenchId = 'formBenchId',
  selectedFile = 'selectedFile',
  selectedFolder = 'selectedFolder',
  editingFile = 'editingFile',
  frn = 'frn',
  // molstar
  structureUrl = 'structureUrl',
  structureUrlFormat = 'structureUrlFormat',
  pdbId = 'pdbId',
  // when a file is selected, it might get associated
  // with an index file or another potential supporting file
  supportFile = 'supportFile',
  logout = 'logout',
  panel = 'panel',
  indicationId = 'indicationId',
  programRunId = 'programRunId',
  uploadOpen = 'uploadOpen',
  accountId = 'accountId',
  previousPage = 'previousPage',
  checkpoint = 'checkpoint',
  stripeStatus = 'stripeStatus',
  locus = 'locus',
  flowRunId = 'flowRunId',
  gene = 'gene',
  entityType = 'entityType',
  entityId = 'entityId',
  selectedIds = 'selectedIds',
  chartType = 'chartType',
  config = 'config',
  vadrConfigId = 'vadrConfigId',
  profileConfigId = 'profileConfigId',
  chartConfigId = 'chartConfigId',
  flowRunType = 'flowRunType',
  flowId = 'flowId',
  flowVersionId = 'flowVersionId',
  tableConfigName = 'tableConfigName',
  conversationId = 'conversationId',
}
