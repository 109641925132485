import { InvitationToken, InvitationTypes } from '../types';
import { api } from './api';

export async function getInvitationToken(
  token: string,
  type: InvitationTypes,
): Promise<InvitationToken> {
  const res = await api.get(`/public/invitations/${type}/${token}`);
  return res.data;
}
