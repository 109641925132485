import {
  AlertColor,
  AlertSnackbar,
  AlertSnackbarProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Link,
  CloseIconButton,
  AlertDialog,
} from '@formbio/ui';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { parseNetworkError } from '../utils/network-error-parser';

type ConfirmationProps = {
  title: string;
  message: string;
  confirmButtonText: string;
  confirmButtonColor: 'primary' | AlertColor;
  confirmButtonIcon?: JSX.Element | undefined;
  optionalChild?: JSX.Element;
  onConfirm: () => Promise<unknown> | unknown;
  closeButtonText?: string;
};
type AlertModalProps = {
  title: string;
  message: string;
  dismissButtonText?: string;
  severity: AlertColor;
  onDismiss?: () => void;
};

interface NotificationContextValue {
  setSnackbar: Dispatch<SetStateAction<AlertSnackbarProps | null>>;
  setConfirmation: Dispatch<SetStateAction<ConfirmationProps | null>>;
  setAlertModal: Dispatch<SetStateAction<AlertModalProps | null>>;
}

export const NotificationContext = createContext<NotificationContextValue>(
  {} as NotificationContextValue,
);

const StyledContent = styled('div')(({ theme }) => ({
  '.MuiLink-root.refresh': {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    fontWeight: 'normal',
  },
}));

export function useOnNetworkError(hideNetworkErrors?: boolean) {
  const { setSnackbar } = useNotificationContext();
  function handleRefresh() {
    window.location.reload();
  }

  if (hideNetworkErrors) {
    return (error: unknown) => {
      console.error(error);
    };
  }

  return (error: unknown) => {
    setSnackbar({
      children: (
        <StyledContent>
          <div>{parseNetworkError(error)}</div>
          <Link onClick={handleRefresh} className='refresh'>
            Refresh Page
          </Link>
        </StyledContent>
      ),
      variant: 'error',
      autoHideDuration: 15000,
    });
  };
}

export function NotificationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [snackbar, setSnackbar] = useState<AlertSnackbarProps | null>(null);
  const [confirmation, setConfirmation] = useState<ConfirmationProps | null>(
    null,
  );
  const [alertModal, setAlertModal] = useState<AlertModalProps | null>(null);

  function handleAlertModal() {
    setAlertModal(null);
    alertModal?.onDismiss?.();
  }

  return (
    <NotificationContext.Provider
      value={{ setSnackbar, setConfirmation, setAlertModal }}
    >
      {children}
      {snackbar && (
        <AlertSnackbar
          {...snackbar}
          onClose={() => {
            snackbar.onClose && snackbar.onClose();
            setSnackbar(null);
          }}
        />
      )}
      {confirmation && (
        <Dialog
          open
          fullWidth
          maxWidth='sm'
          onClose={() => setConfirmation(null)}
        >
          <DialogTitle>
            {confirmation.title}
            <CloseIconButton
              aria-label='close'
              onClick={() => setConfirmation(null)}
            />
          </DialogTitle>
          <DialogContent>
            {confirmation.message}
            {confirmation.optionalChild && confirmation.optionalChild}
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              color='primary'
              onClick={() => setConfirmation(null)}
            >
              {confirmation.closeButtonText || 'Close'}
            </Button>
            <Button
              variant='contained'
              startIcon={confirmation.confirmButtonIcon}
              color={confirmation.confirmButtonColor}
              type='button'
              onClick={() => {
                confirmation.onConfirm();
                setConfirmation(null);
              }}
            >
              {confirmation.confirmButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {alertModal && (
        <AlertDialog alertModal={alertModal} handleClose={handleAlertModal} />
      )}
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NotificationContext);
}
