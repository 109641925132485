import { delay, http, HttpResponse, passthrough } from 'msw';
import {
  agents,
  queriesInGroupGProject,
  queriesInGroupSBX,
} from './data/ai-queries';

const getAIQueryGroups = http.get(
  '*/api/v1/orgs/:oid/:pid/query-group/list',
  async () => {
    return passthrough();
  },
);

const getAIQueriesInGroup = http.get(
  '*/api/v1/orgs/:oid/:pid/query-group/:conversationId',
  async ({ params }) => {
    await delay(50);
    if (params.oid === 'sbx-uat') {
      return HttpResponse.json(queriesInGroupSBX, { status: 200 });
    }
    return HttpResponse.json(queriesInGroupGProject, { status: 200 });
  },
);

const getAgents = http.get('*/api/v1/orgs/:oid/:pid/query/agents', async () => {
  await delay(50);
  return HttpResponse.json(agents, { status: 200 });
});

export const aiQueries = [getAIQueryGroups, getAIQueriesInGroup, getAgents];
