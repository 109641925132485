import { useEffect, useState } from 'react';
import { styled } from '@formbio/ui/components';
import { Button } from '@formbio/ui/components/Button';
import {
  Dialog,
  DialogContent,
  DialogActions,
} from '@formbio/ui/components/Dialog';
import { DialogHeader } from '@formbio/ui/components/Dialog/DialogHeader';
import { Typography } from '@formbio/ui/components/Typography';
import useIdle from '@formbio/ui/hooks/useIdleTimeout';
import { formatTimeLeft } from '@formbio/ui/utils/datetime';
import {
  AUTH_IDLE_TIMEOUT,
  AUTH_PROMPT_TIMEOUT,
} from '@formbio/ui/constants/auth';

const HighlightTime = styled('span')(({ theme }) => ({
  color: theme.palette.error[400],
  fontWeight: theme.typography.fontWeightBold,
}));

type SessionIdleDialogProps = {
  logout: (inactive?: boolean) => void;
  idleTimeout?: number;
  promptTimeout?: number;
};

const SessionIdleDialog = ({
  logout,
  idleTimeout,
  promptTimeout,
}: SessionIdleDialogProps) => {
  const [openModal, setOpenModal] = useState(false);
  const handleIdle = () => {
    setOpenModal(true);
  };
  const { idleTimer, isIdle } = useIdle({
    onIdle: handleIdle,
    idleTime: idleTimeout ? idleTimeout : AUTH_IDLE_TIMEOUT,
    promptTime: promptTimeout ? promptTimeout : AUTH_PROMPT_TIMEOUT,
  });

  const handleStay = () => {
    setOpenModal(false);
    idleTimer.reset();
  };

  useEffect(() => {
    if (isIdle) {
      logout(true);
      setOpenModal(false);
    }
  }, [isIdle]);

  const isAlert = false;

  return (
    <Dialog open={openModal} onClose={handleStay}>
      <DialogHeader
        title='Your session is about to expire'
        onClose={handleStay}
        isAlert={isAlert}
      />
      <DialogContent>
        <Typography>
          You will be automatically signed out in{' '}
          <HighlightTime>
            {formatTimeLeft(idleTimer.getRemainingTime())}
          </HighlightTime>
          <br />
          <br />
          Do you want to stay signed in?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={() => {
            logout();
            setOpenModal(false);
          }}
        >
          No, sign me out
        </Button>
        <Button variant='contained' onClick={handleStay}>
          Yes, stay signed in
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionIdleDialog;
