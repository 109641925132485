import { styled } from '@mui/material';
import { ChevronDownBackgroundIcon } from '../../Icons';
import { ListItemButton } from '../../List';
import { Tooltip } from '../../Tooltip';
import { Typography } from '../../Typography';

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: propName => propName !== 'mini',
})<{ mini: boolean }>(({ mini, theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: mini ? 0 : theme.spacing(3),
  paddingRight: mini ? 0 : theme.spacing(3),
  justifyContent: mini ? 'center' : 'left',
  borderRadius: 0,
  ':hover': {
    backgroundColor: theme.palette.primary[800],
    borderColor: theme.palette.primary[800],
  },
  '.current-proj-org': {
    color: theme.palette.primary[200],
  },
  '.chevron-icon': {
    marginLeft: mini ? theme.spacing(1) : 'auto',
  },
}));

export function ProjectOrgSelectMenuButton({
  title,
  tooltip,
  mini = false,
  handleMenuOpen,
  open,
}: {
  title?: string;
  tooltip?: string;
  mini?: boolean;
  handleMenuOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  open?: boolean;
}) {
  return (
    <Tooltip placement='right' title={tooltip}>
      <StyledListItemButton
        mini={mini}
        onClick={handleMenuOpen}
        id='project-org-btn'
        aria-labelledby='project-org-label'
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'project-org-menu' : undefined}
        aria-haspopup='true'
      >
        {title && (
          <Typography variant='h4' noWrap className='current-proj-org pr-1'>
            {title}
          </Typography>
        )}
        <ChevronDownBackgroundIcon className='chevron-icon' />
      </StyledListItemButton>
    </Tooltip>
  );
}
