import { noteHandlers } from './notes';
import { ldHandlers } from './launch-darkly';
import { workflowHandlers } from './workflows';
import { projectHandlers } from './project';
import { externalHandlers } from './external';
import { vaultHandlers } from './vault';
import { HttpHandler } from 'msw';
import { gitHubAuthHandlers } from './github-auth';
import { memberHandlers } from './members';
import { genomeHandlers } from './genomes';
import { journeyHandlers } from './journeys';
import { containerAppHandlers } from './container-apps';
import { authHandlers } from './auth';
import { igvHandlers } from './igv';
import { provenanceHandlers } from './provenance';
import { programHandlers } from './programs';
import {
  accountIndividualHandlers,
  accountTeamHandlers,
  accountProHandlers,
} from './account';
import { assayHandlers } from './assays';
import { sampleHandlers } from './samples';
import { sampleSetHandlers } from './sample-sets';
import { vadrScoresHandlers } from './vadr-scores';
import { vectorHandlers } from './vectors';
import { aiQueries } from './ai-queries';

interface Handlers {
  [key: string]: HttpHandler[];
}

// When creating a new handler, add it here
// and to the LaunchDarkly mock-data-handlers Variation for "All Handlers"
const allHandlers: Handlers = {
  workflowHandlers,
  projectHandlers,
  externalHandlers,
  ldHandlers,
  noteHandlers,
  vaultHandlers,
  gitHubAuthHandlers,
  memberHandlers,
  genomeHandlers,
  journeyHandlers,
  containerAppHandlers,
  authHandlers,
  igvHandlers,
  provenanceHandlers,
  programHandlers,
  // temporary until we pushed the new handlers to SBX
  accountHandlers: accountIndividualHandlers,
  accountIndividualHandlers,
  accountTeamHandlers,
  accountProHandlers,
  assayHandlers,
  sampleHandlers,
  sampleSetHandlers,
  vadrScoresHandlers,
  vectorHandlers,
  aiQueries,
};

export function buildHandlers(activeHandlerNames: string[]) {
  return activeHandlerNames
    .flatMap(handlerName => allHandlers[handlerName])
    .filter(handler => !!handler);
}
