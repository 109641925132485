import { useAuth } from '@formbio/auth';
import {
  useProject,
  type Member,
  type Organization,
  type Project,
  type Role,
  useOrganization,
} from '@formbio/api';
import useUrlParams from '@formbio/utils';

export enum ProjectPermissions {
  MEMBERSHIP_CREATE = 'project.membership.create',
  MEMBERSHIP_DELETE = 'project.membership.delete',
  MEMBERSHIP_UPDATE = 'project.membership.update',
  MEMBERSHIP_VIEW = 'project.membership.view',
  DELETE = 'project.delete',
  UPDATE = 'project.update',
  STORAGE_CREATE = 'project.storage.create',
  STORAGE_DELETE = 'project.storage.delete',
  STORAGE_UPDATE = 'project.storage.update',
  TOKEN_CREATE = 'project.token.create',
  TOKEN_DELETE = 'project.token.delete',
  TOKEN_USE = 'project.token.use',
  WORKFLOW_RUN_CREATE = 'project.workflow_run.create',
  WORKFLOW_RUN_DELETE = 'project.workflow_run.delete',
  WORKFLOW_RUN_UPDATE = 'project.workflow_run.update',
  WORKFLOW_RUN_VIEW = 'project.workflow_run.view',
}

export enum OrgPermissions {
  DELETE = 'org.delete',
  MEMBERSHIP_CREATE = 'org.membership.create',
  MEMBERSHIP_UPDATE = 'org.membership.update',
  MEMBERSHIP_DELETE = 'org.membership.delete',
  PROJECT_CREATE = 'org.project.create',
  PROJECT_UPDATE = 'org.update',
}

export function useAccessControl(
  permission: OrgPermissions,
  entity: Organization | undefined,
): boolean;

export function useAccessControl(
  permission: OrgPermissions[],
  entity: Organization | undefined,
): boolean[];

export function useAccessControl(
  permission: ProjectPermissions,
  entity: Project | undefined,
): boolean;

export function useAccessControl(
  permission: ProjectPermissions[],
  entity: Project | undefined,
): boolean[];

/**
 * @param {OrgPermissions | ProjectPermissions} permission - Which permission(s) we want to check for
 * @param {Organization | Project} entity - Project or organization
 * @returns {boolean | boolean[]} Indicating permission(s) is allowed for user
 */
export function useAccessControl(
  permission:
    | OrgPermissions
    | ProjectPermissions
    | OrgPermissions[]
    | ProjectPermissions[],
  entity: Organization | Project | undefined,
): boolean | boolean[] {
  const member = useMember(entity);

  // NB: Org "Member" roles may not have any permissions tied to them
  if (!member || !member.scopes) {
    if (Array.isArray(permission)) {
      return permission.map(_ => false);
    } else {
      return false;
    }
  }

  if (Array.isArray(permission)) {
    return permission.map(perm => !!member.scopes?.find(el => el === perm));
  } else {
    return !!member.scopes?.find(scope => scope === permission);
  }
}

function useMember(entity: Organization | undefined): Member | undefined;

function useMember(entity: Project | undefined): Member | undefined;

function useMember(
  entity: Organization | Project | undefined,
): Member | undefined {
  const { user } = useAuth();

  if (!user) {
    return;
  }

  return entity?.members?.find(member => member.user.email === user.email);
}

export function useRole(
  entity: Organization | Project | undefined,
): Role | undefined {
  const member = useMember(entity);

  return member?.role;
}

export function useProjectRole() {
  const { orgId, pid } = useUrlParams();
  const { data: project } = useProject({
    oid: orgId,
    pid,
  });

  return useRole(project);
}

export function useOrgRole() {
  const { orgId } = useUrlParams();
  const { data: org } = useOrganization(orgId);

  return useRole(org);
}
