import { Components } from 'react-markdown';
import Body2 from './text/Body2';
import H3 from './text/H3';
import H4 from './text/H4';
import H5 from './text/H5';
import H6 from './text/H6';
import LI from './text/LI';

// ReactMarkdown components with minimal elements for use in
// other packages.
// Add more components as needed.
export const markdownTextComponents: Components = {
  // Header tag mapping matches Figma design
  h1: H3,
  h2: H4,
  h3: H5,
  h4: H6,
  p: Body2,
  li: LI,
};
