import { useState } from 'react';
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateCurrentUser,
} from 'firebase/auth';
import { FirebaseError } from '@firebase/util';
import { useAuth } from './useAuth';
import { FirebaseApp } from '@firebase/app';

function useReAuth(
  app: FirebaseApp,
  captureError: ({
    name,
    error,
    context,
  }: {
    name: string;
    error: unknown;
    context:
      | {
          [key: string]: unknown;
        }
      | null
      | undefined;
  }) => void,
) {
  const auth = getAuth(app);
  const { user, loading: isUserLoading } = useAuth();
  // need to return error as we need to take different actions. comparing to string (msg) can not be reliable
  const [error, setError] = useState<FirebaseError>();
  const [loading, setLoading] = useState<boolean>(false);

  const request = async ({ currentPassword }: { currentPassword: string }) => {
    setLoading(true);
    setError(undefined);
    try {
      if (!isUserLoading && user?.email) {
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword,
        );

        const response = await reauthenticateWithCredential(user, credential);

        if (response.user) {
          updateCurrentUser(auth, response.user);
          return response.user;
        }
      }
    } catch (error: unknown) {
      captureError({
        name: 'useReAuthentication hook error',
        error: error,
        context: undefined,
      });
      if (error instanceof FirebaseError) {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return { request, error, loading };
}

export { useReAuth };
