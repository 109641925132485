import type { AuthUser } from '../context';

const isGoogleProvider = ({ user }: { user: AuthUser }) => {
  return !!user.providerData.find(
    provider => provider.providerId === 'google.com',
  );
};

const isEmailProvider = ({ user }: { user: AuthUser }) => {
  return !!user.providerData.find(
    provider => provider.providerId === 'password',
  );
};

export { isGoogleProvider, isEmailProvider };
