import { styled } from '@mui/material';
import { List, ListItem } from '../../List';
import { Typography } from '../../Typography';
import { ProjectOrgSelectMenuButton } from '../ProjectOrgSelectMenuButton';

const StyledListItem = styled(ListItem, {
  shouldForwardProp: propName => propName !== 'mini',
})<{ mini: boolean }>(({ mini, theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: mini ? 0 : theme.spacing(3),
  paddingRight: mini ? 0 : theme.spacing(3),
  justifyContent: mini ? 'center' : 'left',
  '#project-org-label': {
    fontWeight: 'bold',
    color: theme.palette.primary[500],
  },
}));

export function ProjectOrgSelectWithLabel({
  title,
  label,
  tooltip,
  mini = false,
  handleMenuOpen,
  open,
}: {
  title?: string;
  label: string;
  tooltip?: string;
  mini?: boolean;
  handleMenuOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  open?: boolean;
}) {
  return (
    <List>
      <StyledListItem mini={mini}>
        <Typography
          id='project-org-label'
          component='label'
          variant='body2'
          htmlFor='project-org-btn'
        >
          {label}
        </Typography>
      </StyledListItem>
      <ProjectOrgSelectMenuButton
        title={title}
        tooltip={tooltip}
        mini={mini}
        handleMenuOpen={handleMenuOpen}
        open={open}
      />
    </List>
  );
}
