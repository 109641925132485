import { Agent, QueryGroup } from '@formbio/api';

export const agents: Agent[] = [
  {
    name: 'Datacore',
    agentType: 'datacore',
  },
  {
    name: 'Analysis',
    agentType: 'analysis',
  },
  {
    name: 'Visualization',
    agentType: 'visualization',
  },
  {
    name: 'Expert',
    agentType: 'expert',
  },
];

export const queriesInGroupGProject: QueryGroup = {
  id: '1',
  title: 'Test Query Group',
  queries: [
    {
      id: '1',
      agentType: 'datacore',
      queryGroupId: '1',
      createdAt: new Date().toISOString(),
      userInput: 'a datacore query',
      usedAgentRouter: false,
      parentId: '',
      responses: [
        {
          type: 'file|json',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/2025-02-25T10:48:10.json',
        },
        {
          type: 'file|sql',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/test.sql',
        },
      ],
    },
    {
      id: '2',
      agentType: 'visualization',
      queryGroupId: '1',
      createdAt: new Date().toISOString(),
      userInput: 'a visualization query',
      usedAgentRouter: false,
      parentId: '1',
      responses: [
        {
          type: 'file|python',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/2025-02-25T12:14:19.py',
        },
      ],
    },
    {
      id: '3',
      agentType: 'analysis',
      queryGroupId: '1',
      createdAt: new Date().toISOString(),
      userInput: 'an analysis query with file|text',
      usedAgentRouter: true,
      parentId: '1',
      responses: [
        {
          type: 'file|text',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/2025-02-24T09:42:45.txt',
        },
      ],
    },
    {
      id: '4',
      agentType: 'analysis',
      queryGroupId: '1',
      createdAt: new Date().toISOString(),
      userInput: 'the same analysis query with file|markdown',
      usedAgentRouter: true,
      parentId: '1',
      responses: [
        {
          type: 'file|markdown',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/2025-02-24T09:42:45.txt',
        },
      ],
    },
    {
      id: '5',
      agentType: 'visualization',
      queryGroupId: '1',
      createdAt: new Date().toISOString(),
      userInput: 'visualization query with file|json',
      usedAgentRouter: true,
      parentId: '1',
      responses: [
        {
          type: 'file|json',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/2025-02-24T09:42:19.json',
        },
      ],
    },
    {
      id: '6',
      agentType: 'analysis',
      queryGroupId: '1',
      createdAt: new Date().toISOString(),
      userInput: 'an html file in an iFrame & 2 images',
      usedAgentRouter: true,
      parentId: '1',
      responses: [
        {
          type: 'file|html',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/valval.results.html',
        },
        {
          type: 'file|png',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/minion.png',
        },
        {
          type: 'file|png',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/code-y-background.png',
        },
      ],
    },
    {
      id: '7',
      agentType: 'visualization',
      queryGroupId: '1',
      createdAt: new Date().toISOString(),
      userInput: 'visualization query with file|json & chart',
      usedAgentRouter: true,
      parentId: '1',
      responses: [
        {
          type: 'file|json',
          filePath:
            'gs://colossal-dev-67be3d4c-f5a7-4e48-95c8-c7c5be5854b7/.formbio/data/query_results/penguins.json',
        },
      ],
    },
  ],
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
};

export const queriesInGroupSBX: QueryGroup = {
  id: '4044bff1-f246-4b06-80a8-06f94646c088',
  title: 'Get me 10 vectors',
  createdAt: '2025-02-26T19:22:08.789533Z',
  updatedAt: '2025-02-26T19:23:15.70784Z',
  queries: [
    {
      id: 'a7e8ca2c-5cae-48c5-a056-8d6352ee3288',
      parentId: '35348c21-4e3c-4892-99e8-7f756d873c53',
      userInput:
        'Give me a bar chart visualizing the sequence length of the vectors.',
      responses: [
        {
          type: 'file|python',
          filePath:
            'gs://colossal-dev-40c9baa0-9156-4e5e-a784-f3ffffd022ef/.formbio/data/query_results/2025-02-26T19:23:15.py',
        },
        {
          type: 'file|json',
          filePath:
            'gs://colossal-dev-40c9baa0-9156-4e5e-a784-f3ffffd022ef/.formbio/data/query_results/2025-02-26T19:23:15.json',
        },
      ],
      agentType: 'visualization',
      createdAt: '2025-02-26T19:23:15.70784Z',
      queryGroupId: '4044bff1-f246-4b06-80a8-06f94646c088',
      usedAgentRouter: false,
    },
    {
      id: '30714a77-1413-4f59-a214-0456324ec385',
      parentId: '35348c21-4e3c-4892-99e8-7f756d873c53',
      userInput: "Which of the vectors include a feature named 'attB4'",
      responses: [
        {
          type: 'file|text',
          filePath:
            'gs://colossal-dev-40c9baa0-9156-4e5e-a784-f3ffffd022ef/.formbio/data/query_results/2025-02-26T19:22:44.txt',
        },
      ],
      agentType: 'analysis',
      createdAt: '2025-02-26T19:22:44.145767Z',
      queryGroupId: '4044bff1-f246-4b06-80a8-06f94646c088',
      usedAgentRouter: false,
    },
    {
      id: '35348c21-4e3c-4892-99e8-7f756d873c53',
      parentId: '',
      userInput: 'Get me 10 vectors',
      responses: [
        {
          type: 'file|json',
          filePath:
            'gs://colossal-dev-40c9baa0-9156-4e5e-a784-f3ffffd022ef/.formbio/data/query_results/2025-02-26T19:22:08.json',
        },
        {
          type: 'file|sql',
          filePath:
            'gs://colossal-dev-40c9baa0-9156-4e5e-a784-f3ffffd022ef/.formbio/data/query_results/2025-02-26T19:22:08.sql',
        },
      ],
      agentType: 'datacore',
      createdAt: '2025-02-26T19:22:08.799175Z',
      queryGroupId: '4044bff1-f246-4b06-80a8-06f94646c088',
      usedAgentRouter: false,
    },
  ],
};
