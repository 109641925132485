import { initDataGridProLicense } from './data-grid-pro-license';
import {
  reduceTextToTwoLetters,
  pluralize,
  trimStringWithMiddleEllipsis,
  tallyArray,
  roundToDecimalPlaces,
} from './formatting';
import { isString } from './type-guards';
import { hexToRGBA } from './colors';
import { downloadStringifiedJson, downloadTextContent } from './downloadObject';
import { handleSaveToPng } from './export-to-image';

export {
  reduceTextToTwoLetters,
  pluralize,
  isString,
  hexToRGBA,
  initDataGridProLicense,
  trimStringWithMiddleEllipsis,
  downloadStringifiedJson,
  tallyArray,
  roundToDecimalPlaces,
  handleSaveToPng,
  downloadTextContent,
};
