import ChartIcon from './Navigation/ChartIcon';
import CaretDownFilledBackgroundIcon from './CaretDownFilledBackgroundIcon';
import CaretDownFilledBackgroundRoundedIcon from './CaretDownFilledBackgroundRoundedIcon';
import ChevronDownBackgroundIcon from './ChevronDownBackgroundIcon';
import CircleLoaderIcon from './CircleLoaderIcon';
import GoogleLogoColorIcon from './GoogleLogoColorIcon';
import SpinnerForAnimationIcon from './SpinnerForAnimationIcon';
import TwoToneCheckIcon from './TwoToneCheckIcon';
import { WarningCircleIconError } from './WarningCircleIconError';
import CheckpointIcon from './CheckpointIcon';
import PulsingDnaIcon from './PulsingDnaIcon';
import PrecisionManufacturingRoundedIcon from '@mui/icons-material/PrecisionManufacturingRounded';
import {
  UnfoldLess as UnfoldLessIcon,
  UnfoldMore as UnfoldMoreIcon,
} from '@mui/icons-material';

export {
  // icons used in navigation
  ChartIcon,
  // other icons
  CaretDownFilledBackgroundIcon,
  CaretDownFilledBackgroundRoundedIcon,
  ChevronDownBackgroundIcon,
  CircleLoaderIcon,
  GoogleLogoColorIcon,
  SpinnerForAnimationIcon,
  TwoToneCheckIcon,
  WarningCircleIconError,
  CheckpointIcon,
  PulsingDnaIcon,
  PrecisionManufacturingRoundedIcon,
  UnfoldLessIcon,
  UnfoldMoreIcon,
};

export * from '@phosphor-icons/react';
