import { Fade } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

export default function DelayedFade({
  children,
  delay = 0,
  inProp,
  timeout = 0,
}: {
  children: ReactElement;
  delay?: number;
  timeout?: number;
  inProp?: boolean;
}) {
  const [isIn, setIsIn] = useState(inProp && delay === 0);

  useEffect(() => {
    if (delay > 0) {
      setTimeout(() => setIsIn(true), delay);
    }
  }, [delay]);

  return (
    <Fade in={isIn} timeout={timeout}>
      {children}
    </Fade>
  );
}
