import { AgentType, ChatData, QueryGroup } from '../../types';
import { api } from '../../utils/api';

export async function fetchAll(params: {
  orgId: string;
  pid: string;
}): Promise<QueryGroup[]> {
  return api
    .get<QueryGroup[]>(`/orgs/${params.orgId}/${params.pid}/query-group/list`, {
      params,
    })
    .then(({ data }) => data);
}

export async function fetchOne(params: {
  orgId: string;
  pid: string;
  conversationId: string;
}): Promise<QueryGroup> {
  return api
    .get<QueryGroup>(
      `/orgs/${params.orgId}/${params.pid}/query-group/${params.conversationId}`,
    )
    .then(({ data }) => data);
}

export async function createQueryGroup(params: {
  orgId: string;
  pid: string;
  query: string;
}) {
  return api.post<QueryGroup>(
    `/orgs/${params.orgId}/${params.pid}/query-group`,
    {
      title: params.query,
    },
  );
}

export async function createQuery(params: {
  orgId: string;
  pid: string;
  query: string;
  conversationId?: string;
  agentType?: AgentType;
  parentId?: string;
}) {
  const baseUrl = `/orgs/${params.orgId}/${params.pid}/query`;

  return api.post<ChatData>(
    params.agentType ? `${baseUrl}/${params.agentType}` : baseUrl,
    {
      query: params.query,
      parentId: params.parentId,
      queryGroupId: params.conversationId,
    },
  );
}

export async function deleteQueryGroup(params: {
  orgId: string;
  pid: string;
  conversationId: string;
}) {
  return api.delete(
    `/orgs/${params.orgId}/${params.pid}/query-group/${params.conversationId}`,
  );
}

export async function deleteQuery(params: {
  orgId: string;
  pid: string;
  id: string;
}) {
  return api.delete(`/orgs/${params.orgId}/${params.pid}/query/${params.id}`);
}
