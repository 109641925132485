import { useSignInWithGoogle } from '@formbio/auth';
import SignInWithGoogle from '@formbio/ui/components/Button/SignInWithGoogle';
import { User } from 'firebase/auth';
import { useEffect } from 'react';

type Props = {
  onSuccess: (user: User) => void;
};

export default function SignUpWithGoogleWrapper({ onSuccess }: Props) {
  const { request, user, loading } = useSignInWithGoogle();

  useEffect(() => {
    if (user) {
      onSuccess(user);
    }
  }, [user]);

  return (
    <SignInWithGoogle
      onClick={() => request()}
      loading={loading}
      label='Sign up With Google'
    />
  );
}
