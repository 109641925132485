import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { fetchAll } from '../../services/agent-types';
import { Agent, AgentType } from '../../types';

const AGENT_TYPES_KEY = 'agent-types';

export function useAgents({
  params,
  options = {},
}: {
  params: Parameters<typeof fetchAll>[0];
  options?: Omit<UseQueryOptions<Agent[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<Agent[]>({
    queryKey: [AGENT_TYPES_KEY, params],
    queryFn: () => fetchAll(params),
    ...options,
  });
}

/**
 * A function to centralize the logic for determining if an agent needs a parent ID.
 * @param agentType The agent type to check.
 * @returns Whether the agent needs a parent ID.
 */
export function agentNeedsParentId(agentType?: AgentType) {
  return agentType && ['analysis', 'visualization'].includes(agentType);
}
